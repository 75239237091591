import { FormattedMessage } from '@repo/i18n'
import { useMemo } from 'react'

import { AdvancedTable } from '../../../components/advanced-table'
import { type AdvancedTableColumns } from '../../../components/advanced-table/types'
import { sortNumbers, sortStrings } from '../../../utils/sort-functions'
import { useDashboardContext } from '../../shared/dashboard-context'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

const columns: AdvancedTableColumns<{
  id: string
  cohortName: string
  traineeNumber: number
}> = [
  {
    key: 'cohortName',
    title: <FormattedMessage id="dashboard.cards.traineeCount.details.tab.cohortName" />,
    field: 'cohortName',
    sorter: (a, b) => sortStrings(a.cohortName, b.cohortName),
  },
  {
    key: 'traineeNumber',
    title: (
      <FormattedMessage id="dashboard.cards.traineeCount.details.tab.traineeNumber" />
    ),
    field: 'traineeNumber',
    sorter: (a, b) => sortNumbers(a.traineeNumber, b.traineeNumber),
    align: 'end',
  },
]

export const TraineeCountCardDetails = () => {
  const { dataSlot } = useDashboardCardData('traineeCount')
  const { pageState } = useDashboardContext()

  const tableRows = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.cohortNames.map((cohortName, index) => ({
      id: data.cohortExtIds[index]!,
      cohortName,
      traineeNumber:
        data.numUsersWithRecordingSessionsAccPerTimestamp[index]!.at(-1) ?? 0,
    }))
  }, [dataSlot])

  return (
    <DetailPageWrapper cardId="traineeCount">
      <AdvancedTable
        rows={tableRows}
        isLoading={pageState === 'loading'}
        keyExtractor={record => record.id}
        columns={columns}
      />
    </DetailPageWrapper>
  )
}
