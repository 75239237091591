import {
  Button,
  Checkbox,
  HStack,
  Image,
  Select,
  type SystemStyleObject,
} from '@chakra-ui/react'
import { AVAILABLE_PROGRAM_LANGUAGES, type ProgramLanguages } from '@repo/api'
import { FormattedMessage, languageLabels, useIntl } from '@repo/i18n'
import { colors, PageHeader, showToast } from '@repo/ui'
import { useCallback, useMemo, useState } from 'react'
import { RiAddFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { ConnectExerciseModal } from '../../builder/exercises/connect-exercise-modal'
import { AdvancedTable } from '../../components/advanced-table'
import { type AdvancedTableColumns } from '../../components/advanced-table/types'
import { AppHeaderTitle } from '../../components/app-header-title'
import { PageList } from '../../containers/page-list'
import { type Exercise } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { sortStrings } from '../../utils/sort-functions'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { EnglishExercisesData } from './exercise-data/english-exercises-data'
import { GermanExercisesData } from './exercise-data/german-exercises-data'
import { ItalianExercisesData } from './exercise-data/italian-exercises-data'
import { PortugueseExercisesData } from './exercise-data/portuguese-exercise-data'
import { SpanishExercisesData } from './exercise-data/spanish-exercises-data'
import { type HardcodedExerciseList } from './exercise-data/types'

const keyExtractor = (record: HardcodedExerciseList[number]) => record.extId
const tableSx: SystemStyleObject = { minHeight: '90%' }

export const ImportExercises = () => {
  const { mutate } = useOpenapiSWR('getExercises')
  const navigate = useNavigate()
  const [exerciseData, setExerciseData] = useState(EnglishExercisesData)
  const [isOpenConnectToProgramModal, setIsOpenConnectToProgramModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedLanguage, setSelectedLanguage] = useState<ProgramLanguages>('en-US')
  const { formatMessage } = useIntl()
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [selectedExercises, setSelectedExercises] = useState<Array<string>>([])

  const selectLanguage = event => {
    switch (event.target.value as ProgramLanguages) {
      case 'en-US':
        setExerciseData(EnglishExercisesData)
        break
      case 'de-DE':
        setExerciseData(GermanExercisesData)
        break
      case 'es-ES':
        setExerciseData(SpanishExercisesData)
        break
      case 'it-IT':
        setExerciseData(ItalianExercisesData)
        break
      case 'pt-PT':
        setExerciseData(PortugueseExercisesData)
        break
      default:
        // If we select a language without data, we should clear the list
        setExerciseData([])
        break
    }

    setSelectedLanguage(event.target.value)
    setSelectedExercises([])
  }

  const handleToggle = e => {
    const { value, checked } = e.target

    if (checked) {
      // push selected value in list
      setSelectedExercises(prev => [...prev, value])
    } else {
      // remove unchecked value from the list
      setSelectedExercises(prev => prev.filter(x => x !== value))
    }
  }

  const selectedExercisesData = useMemo(
    () => exerciseData.filter(ex => selectedExercises.includes(ex.extId)),
    [exerciseData, selectedExercises]
  )

  const createExercises = async (programExtIds: Array<string>) => {
    try {
      setIsSubmitting(true)
      const responses = await Promise.all(
        selectedExercisesData.map(exercise =>
          client.post('createExercise', {
            body: { programExtIds, exerciseData: exercise },
          })
        )
      )

      if (responses.some(r => r.error)) {
        throw new Error()
      }

      await mutate()
      setIsOpenConnectToProgramModal(false)
      navigate('/exercises')
      showToast({ messageKey: 'common.alert.created', status: 'success' })
    } catch (error) {
      showToast({ messageKey: 'common.error.unexpected', status: 'error' })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSelectAll = useCallback(() => {
    const isChecked = !isCheckAll

    setSelectedExercises(isChecked ? exerciseData.map(exercise => exercise.extId) : [])
    setIsCheckAll(isChecked)
  }, [exerciseData, isCheckAll])

  const columns = useMemo<
    AdvancedTableColumns<Pick<Exercise, 'title' | 'type' | 'extId' | 'imageUrl'>>
  >(
    () => [
      {
        key: 'selectAll',
        title: <Checkbox isChecked={isCheckAll} onChange={handleSelectAll} />,
        customRender: record => (
          <Checkbox
            value={record.extId}
            onChange={handleToggle}
            isChecked={selectedExercises.includes(record.extId)}
          />
        ),
      },
      {
        key: 'imageUrl',
        title: <FormattedMessage id="coaching.exerciseList.table.image" />,
        field: 'imageUrl',
        customRender: record => <Image w="100px" src={record.imageUrl} />,
      },
      {
        key: 'title',
        title: <FormattedMessage id="coaching.exerciseList.table.title" />,
        field: 'title',
        sorter: (a, b) => sortStrings(a.title, b.title),
      },
      {
        key: 'type',
        title: <FormattedMessage id="coaching.exerciseList.table.variable" />,
        field: 'type',
        sorter: (a, b) => sortStrings(a.type, b.type),
        customRender: record => (
          <FormattedMessage id={`exercise.type.${record.type}` satisfies I18nKey} />
        ),
      },
    ],
    [handleSelectAll, isCheckAll, selectedExercises]
  )

  return (
    <PageList display="flex" flexDir="column">
      <AppHeaderTitle formattedMessageId="coaching.exerciseList.title.import" />
      <PageHeader
        buttons={
          <HStack>
            <Select
              placeholder={formatMessage({ id: 'coaching.exercise.language.import' })}
              onChange={selectLanguage}
              value={selectedLanguage}
            >
              {AVAILABLE_PROGRAM_LANGUAGES.map(key => (
                <option key={key} value={key}>
                  {languageLabels[key]}
                </option>
              ))}
            </Select>
            <Button
              onClick={() => setIsOpenConnectToProgramModal(true)}
              leftIcon={<RiAddFill size="1.25em" />}
              iconSpacing="1"
              flexShrink={0}
              alignSelf="flex-end"
              isDisabled={!selectedExercises.length}
              _hover={{
                bgColor: colors['primary-dark'],
                color: colors.buttonColor,
              }}
            >
              <FormattedMessage id="coaching.exerciseList.button.add" />
            </Button>
          </HStack>
        }
      />
      <ConnectExerciseModal
        exercises={selectedExercisesData.map(e => ({
          ...e,
          programExtIds: [],
          createdAt: '',
          updatedAt: '',
        }))}
        onSubmit={createExercises}
        isOpen={isOpenConnectToProgramModal}
        onClose={() => setIsOpenConnectToProgramModal(false)}
        isSubmitting={isSubmitting}
      />
      <AdvancedTable
        rows={exerciseData}
        keyExtractor={keyExtractor}
        columns={columns}
        sx={tableSx}
      />
    </PageList>
  )
}
