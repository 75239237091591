import { Box, HStack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { useMemo } from 'react'

import { AdvancedTable } from '../../../components/advanced-table'
import { type AdvancedTableColumns } from '../../../components/advanced-table/types'
import { sortNumbers, sortStrings } from '../../../utils/sort-functions'
import { useDashboardContext } from '../../shared/dashboard-context'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

const columns: AdvancedTableColumns<{
  id: number
  scenarioName: string
  completions: number
  index: number
}> = [
  {
    key: 'scenarioName',
    title: (
      <FormattedMessage id="dashboard.cards.popularScenarios.details.tab.scenarioName" />
    ),
    field: 'scenarioName',
    sorter: (a, b) => sortStrings(a.scenarioName, b.scenarioName),
    customRender: record => (
      <HStack gap="12px">
        <Box minW="20px" w="20px" h="20px" borderRadius="50%" bg={colors.blue[500]}>
          <Text textAlign="center" fontSize="12px" color="white">
            {record.index}
          </Text>
        </Box>
        <Text fontSize="14px">{record.scenarioName}</Text>
      </HStack>
    ),
  },
  {
    key: 'completions',
    title: (
      <FormattedMessage id="dashboard.cards.popularScenarios.details.tab.completions" />
    ),
    field: 'completions',
    sorter: (a, b) => sortNumbers(a.completions, b.completions),
    align: 'end',
  },
]

export const PopularScenariosCardDetails = () => {
  const { pageState } = useDashboardContext()
  const { dataSlot } = useDashboardCardData('popularScenarios')

  const mostPopularScenariosData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    const scenarioNameMap = Object.entries(data.scenariosMetadata).reduce(
      (map, [extId, scenarioData]) => {
        map[extId] = scenarioData.scenarioName

        return map
      },
      {}
    )

    return data.mostPopularScenariosOverall.map((scenario, index) => ({
      id: index,
      scenarioName: scenarioNameMap[scenario.scenarioExtId],
      completions: scenario.count,
      index: index + 1,
    }))
  }, [dataSlot])

  return (
    <DetailPageWrapper cardId="popularScenarios">
      <AdvancedTable
        isLoading={pageState === 'loading'}
        rows={mostPopularScenariosData}
        keyExtractor={record => record.id}
        columns={columns}
      />
    </DetailPageWrapper>
  )
}
