import { Skeleton, Stack } from '@chakra-ui/react'

export const TableLoadingRows = () => (
  <Stack gap={8} mt={4}>
    <Skeleton height={8} width="full" />
    <Skeleton height={8} width="full" />
    <Skeleton height={8} width="full" />
    <Skeleton height={8} width="full" />
    <Skeleton height={8} width="full" />
  </Stack>
)
