import { Button, HStack, Icon, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, showToast, Tooltip } from '@repo/ui'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { HiPlus, HiQuestionMarkCircle } from 'react-icons/hi'
import { MdThumbUpAlt } from 'react-icons/md'

import { AdvancedTable } from '../../../../../components/advanced-table'
import { type AdvancedTableColumns } from '../../../../../components/advanced-table/types'
import { CenteredSpinner } from '../../../../../components/centered-spinner'
import { useUserSessionId } from '../../../../../store/entities/user-activity-session/use-user-session-id'
import { type Instruction } from '../../../../../types/api-types'
import { client } from '../../../../../utils/openapi-client'
import { StickyFooter } from '../../../../shared/sticky-footer'
import { useCustomizationStepIntroModal } from '../../../../shared/use-customization-step-intro-modal'
import { useRealtimeBuilderContext } from '../../../shared/realtime-builder-context'
import { useRenderSkillName } from '../../../shared/use-render-skill-name'
import {
  StageStepperProvider,
  useStageStepperContext,
} from '../../shared/stage-stepper-context'
import { StageTabs } from '../../shared/stage-tabs'
import { StatusBadge } from '../../shared/status-badge'
import { useInteractionStepContext } from '../interaction-step-context'
import { MAX_INSTRUCTIONS_PER_STAGE } from './step-instructions/constants'
import { HowToColumn } from './step-instructions/how-to-colum'
import { OptionsMenu } from './step-instructions/options-menu'
import { StageGoalCard } from './step-instructions/stage-goal-card'
import { useCreateInstructionModal } from './step-instructions/use-create-instruction-modal'

const StepInstructionsInner = () => {
  const renderSkillName = useRenderSkillName()

  const { activeStageIndex, goToPreviousStage, goToNextStage } = useStageStepperContext()
  const {
    scenario,
    programExtId,
    trainingExtId,
    scenarioExtId,
    mutateScenario,
    isBuilderReadonly,
  } = useRealtimeBuilderContext()

  const { goToPreviousStep, goToNextStep } = useInteractionStepContext()

  const [createModal, showCreateModal] = useCreateInstructionModal()

  const userSessionId = useUserSessionId()

  const [introModal, showIntroModal] = useCustomizationStepIntroModal()

  const [isGeneratingInstructions, setIsGeneratingInstructions] = useState(true)

  const generateInstructions = useCallback(async () => {
    try {
      const res = await client.post('generateInstructions', {
        params: {
          path: {
            programExtId,
            trainingExtId,
            scenarioExtId,
          },
        },
        body: {
          userActivitySessionExtId: userSessionId,
        },
      })

      if (res.error) {
        throw new Error()
      }

      // TODO make optimistic update
      await mutateScenario()
    } catch (error) {
      showToast({
        messageKey: 'scenario.ai.builder.steps.conversationGeneration.suggestions.failed',
        status: 'error',
      })
    } finally {
      setIsGeneratingInstructions(false)
    }
  }, [programExtId, trainingExtId, scenarioExtId, mutateScenario, userSessionId])

  useEffect(() => {
    const shouldGenerateInstructions = !scenario.missionStages?.[0]?.instructions?.length

    if (shouldGenerateInstructions) {
      generateInstructions()
    } else {
      setIsGeneratingInstructions(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: AdvancedTableColumns<Instruction> = useMemo(
    () => [
      {
        key: 'description',
        title: <FormattedMessage id="common.instructions" />,
        field: 'description',
      },
      {
        key: 'status',
        title: <FormattedMessage id="common.type" />,
        customRender: () => (
          <StatusBadge
            icon={MdThumbUpAlt}
            intlKey="common.show"
            borderColor={colors.green[600]}
            bgColor={colors.green[100]}
          />
        ),
      },
      {
        key: 'skill',
        title: <FormattedMessage id="common.skill" />,
        customRender: record => renderSkillName(record.skill.name),
      },
      {
        key: 'how',
        title: (
          <HStack spacing={1}>
            <Text textTransform="uppercase">
              <FormattedMessage id="common.howTo" />
            </Text>
            <Text textTransform="lowercase" fontWeight="normal">
              (<FormattedMessage id="common.optional" />)
            </Text>
            <Tooltip
              label={
                <FormattedMessage id="scenario.builder.realtime.steps.instructions.how_to.tooltip" />
              }
              hasArrow
              placement="top-end"
              backgroundColor="white"
              p="24px"
            >
              <span>
                <Icon as={HiQuestionMarkCircle} boxSize="18px" color={colors.blue[500]} />
              </span>
            </Tooltip>
          </HStack>
        ),
        customRender: record => <HowToColumn instruction={record} />,
      },
      {
        key: 'actions',
        title: '',
        customRender: record => <OptionsMenu instruction={record} />,
      },
    ],
    [renderSkillName]
  )

  const instructionCountOnActiveStage =
    scenario.missionStages?.[activeStageIndex]?.instructions?.length ?? 0

  const renderPanels = () => (
    <Stack gap={8}>
      <StageGoalCard goal={scenario.missionStages?.[activeStageIndex]?.stageGoal ?? ''} />

      <HStack gap={4} justifyContent="space-between" textAlign="start">
        <Text fontWeight="semibold">
          <FormattedMessage id="scenario.builder.realtime.steps.instructions.tableTitle" />
          :
        </Text>

        <Button
          isDisabled={
            isBuilderReadonly ||
            instructionCountOnActiveStage >= MAX_INSTRUCTIONS_PER_STAGE
          }
          leftIcon={<HiPlus />}
          onClick={showCreateModal}
        >
          <FormattedMessage id="scenario.builder.realtime.steps.instructions.createButton" />
        </Button>
      </HStack>

      <AdvancedTable
        keyExtractor={record => record.extId}
        rows={scenario.missionStages?.[activeStageIndex]?.instructions ?? []}
        columns={columns}
        sx={{ width: '100%' }}
        emptyStateLabelKey="scenario.builder.realtime.steps.instructions.emptyTable"
        withSearchInput={false}
      />
    </Stack>
  )

  const goNextStep = useCallback(async () => {
    goToNextStage()

    if (activeStageIndex === 2) {
      await showIntroModal()

      goToNextStep()
    }
  }, [activeStageIndex, goToNextStage, goToNextStep, showIntroModal])

  const goalCountOnActiveStage =
    scenario.missionStages?.[activeStageIndex]?.instructions?.length ?? 0

  const isCurrentStepValid = goalCountOnActiveStage > 1

  const isTabDisabled = (index: number) =>
    index !== activeStageIndex &&
    (!scenario.missionStages?.[index]?.instructions ||
      scenario.missionStages?.[index]?.instructions?.length === 0)

  if (isGeneratingInstructions) {
    return <CenteredSpinner />
  }

  return (
    <Stack flex={1} gap={0}>
      {createModal}
      {introModal}
      <Text fontSize="16px" pb={1}>
        <FormattedMessage id="scenario.builder.realtime.steps.goal.stages.text" />
      </Text>
      <StageTabs
        renderPanels={renderPanels}
        activeStep={activeStageIndex}
        isTabDisabled={isTabDisabled}
      />

      <StickyFooter
        onBack={activeStageIndex > 0 ? goToPreviousStage : goToPreviousStep}
        onNext={isCurrentStepValid ? goNextStep : undefined}
        isNextDisabled={!isCurrentStepValid}
        nextHintKey={
          !isCurrentStepValid &&
          'scenario.builder.realtime.steps.instructions.disabledHint'
        }
      />
    </Stack>
  )
}

export const StepInstructions = () => (
  <StageStepperProvider>
    <StepInstructionsInner />
  </StageStepperProvider>
)
