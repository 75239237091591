import {
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, Heading, showToast } from '@repo/ui'
import { times } from 'lodash-es'
import { useCallback, useMemo, useState } from 'react'
import { RiAddFill, RiMoreFill } from 'react-icons/ri'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentLoader } from '../../builder/components/content-loader'
import { AdvancedTable } from '../../components/advanced-table'
import { type AdvancedTableColumns } from '../../components/advanced-table/types'
import { CardGrid } from '../../components/card-grid'
import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { getContentStatusOfSWR } from '../../hooks/use-swr-loader'
import { useProgramsList } from '../../new-program-builder/shared/api'
import { formatDateTime } from '../../utils/dates'
import { sortDates, sortStrings } from '../../utils/sort-functions'
import { useBenchmarkProfiles } from '../hooks/use-benchmark-profiles'
import { type BenchmarkProfile } from '../types/benchmark-profile'
import { ActionButtonContainer } from './action-button-container'
import { BenchmarkProfileEditModal } from './benchmark-profile-edit-modal'
import { BenchmarkProfileStateBadge } from './benchmark-profile-state-badge'
import { InfoBox } from './info-box'
import { LinkScenariosModal } from './link-scenarios-modal'
import { FirstWizardModal } from './wizard/first-wizard-modal'

type Params = {
  wizard?: string
}

export const ProfilesTab = () => {
  const { wizard } = useParams<Params>()

  const navigate = useNavigate()
  const { useGetAll, deleteProfile, useTemplateProfiles, duplicateTemplate } =
    useBenchmarkProfiles()

  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenLinkScenarioModal, setIsOpenLinkScenarioModal] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState<BenchmarkProfile>()

  const handleEditModalOpen = (profile: BenchmarkProfile) => {
    setSelectedProfile(profile)
    setIsOpenEditModal(true)
  }

  const handleLinkScenarioModalOpen = (profile: BenchmarkProfile) => {
    setSelectedProfile(profile)
    setIsOpenLinkScenarioModal(true)
  }

  const { formatMessage } = useIntl()

  const showDeleteWarning = () =>
    showToast({
      messageKey: 'benchmarkProfile.delete.hasLinkedScenarios',
      status: 'error',
    })

  const { data: profileData, status: profileStatus, refresh } = useGetAll()
  const { data: programs, error: programsError } = useProgramsList()
  const programStatus = getContentStatusOfSWR(programs, programsError)

  const { data: templates, isLoading } = useTemplateProfiles()

  const refreshBenchmarkList = useCallback(() => refresh(), [refresh])
  const [isOpenWizardModal, setIsOpenWizardModal] = useState(false)
  const hasNoPrograms = useMemo(
    () => (programs ? programs.length === 0 : true),
    [programs]
  )

  const handleDuplicateTemplate = useCallback(
    async (extId: string) => {
      try {
        await duplicateTemplate(extId)
        refreshBenchmarkList?.()
        showToast({
          messageKey: 'coaching.benchmarking.templates.menu.duplicate.success',
          status: 'success',
        })
      } catch (err) {
        showToast({
          messageKey: 'coaching.benchmarking.templates.menu.duplicate.error',
          status: 'error',
        })
      }
    },
    [duplicateTemplate, refreshBenchmarkList]
  )

  const columns: AdvancedTableColumns<BenchmarkProfile> = [
    {
      key: 'status',
      title: <FormattedMessage id="coaching.benchmarking.profiles.table.status" />,
      field: 'status',
      customRender: record => <BenchmarkProfileStateBadge state={record.status} />,
      sorter: (a, b) => sortStrings(a.status, b.status),
    },
    {
      key: 'title',
      title: <FormattedMessage id="common.name" />,
      field: 'title',
      sorter: (a, b) => sortStrings(a.title, b.title),
    },
    {
      key: 'type',
      field: 'type',
      title: <FormattedMessage id="common.type" />,
    },
    {
      key: 'updatedAt',
      title: <FormattedMessage id="coaching.programList.table.update" />,
      field: 'updatedAt',
      customRender: record => formatDateTime(record.updatedAt),
      sorter: (a, b) => sortDates(a.updatedAt, b.updatedAt),
    },
    {
      key: 'linkedScenarios',
      field: 'linkedScenarios',
      title: <FormattedMessage id="coaching.benchmarking.profiles.table.scenarios" />,
      customRender: record =>
        record.status === 'done'
          ? record.linkedScenarios.length
          : record.scenariosToLink.length,
    },

    {
      key: 'actions',
      title: '',
      customRender: record => {
        const hasLinkedScenarios =
          record.linkedScenarios.length > record.scenariosToLink.length

        return (
          <Menu eventListeners={{ scroll: false }}>
            <MenuButton
              as={IconButton}
              variant="ghost"
              icon={<RiMoreFill />}
              onClick={e => e.stopPropagation()}
            />
            <MenuList onClick={e => e.stopPropagation()}>
              <MenuItem onClick={() => handleEditModalOpen(record)}>
                <FormattedMessage id="coaching.benchmarking.profiles.table.actions.rename" />
              </MenuItem>
              <MenuItem
                isDisabled={record.status !== 'done'}
                onClick={() => handleLinkScenarioModalOpen(record)}
              >
                <FormattedMessage id="coaching.benchmarking.profiles.table.actions.link" />
              </MenuItem>
              <InterceptAndConfirm
                onConfirm={async () => {
                  try {
                    await deleteProfile(record.extId)
                    refreshBenchmarkList()
                    showToast({
                      messageKey: 'common.alert.deleted',
                      status: 'success',
                    })
                  } catch (err) {
                    showToast({
                      messageKey: 'general.error.deleting.data',
                      status: 'error',
                    })
                  }
                }}
                title={formatMessage(
                  { id: 'benchmarkProfile.delete.confirmModal.title' },
                  { profileTitle: record.title }
                )}
                description={formatMessage({
                  id: 'benchmarkProfile.delete.confirmModal.description',
                })}
                ctaLabel={formatMessage({
                  id: 'common.delete',
                })}
              >
                {({ openModal }) => (
                  <MenuItem
                    color={colors.indicator['red-dark']}
                    onClick={hasLinkedScenarios ? showDeleteWarning : openModal}
                  >
                    <FormattedMessage id="common.delete" />
                  </MenuItem>
                )}
              </InterceptAndConfirm>
            </MenuList>
          </Menu>
        )
      },
    },
  ]

  return (
    <>
      <ContentLoader status={programStatus}>
        {hasNoPrograms && (
          <InfoBox>
            <FormattedMessage id="coaching.benchmarking.profiles.noPrograms" />
            <Text as={Link} color={colors.primary}>
              <FormattedMessage id="coaching.benchmarking.profiles.noPrograms.link" />
            </Text>
          </InfoBox>
        )}
      </ContentLoader>
      <ActionButtonContainer>
        <Button
          onClick={() => setIsOpenWizardModal(true)}
          isDisabled={hasNoPrograms}
          leftIcon={<RiAddFill size="1.25em" />}
          iconSpacing="1"
          colorScheme="blue"
        >
          <FormattedMessage id="coaching.benchmarking.button.create" />
        </Button>
      </ActionButtonContainer>
      <Text fontSize="24px" mb={4} fontWeight={700} color={colors.gray[900]}>
        <FormattedMessage id="coaching.benchmarking.tabs.benchmark.profiles" />
      </Text>
      <CardGrid>
        {isLoading
          ? times(4).map(i => <CardGrid.SkeletonCard key={i} />)
          : templates?.slice(0, 4)?.map(template => (
              <CardGrid.Card
                key={template.extId}
                title={template.title}
                image={template.icon}
                footer={{
                  type: 'cta',
                  labelKey: 'coaching.benchmarking.templates.menu.duplicate',
                  onClick: () => handleDuplicateTemplate(template.extId),
                }}
              >
                <Text fontSize={14}>{template.description}</Text>
              </CardGrid.Card>
            ))}
      </CardGrid>

      <Button
        p={4}
        m={6}
        alignSelf="center"
        w="max-content"
        variant="outline"
        borderRadius=" 6px"
        onClick={() => navigate('/coaching/benchmarking/profiles/templates')}
        fontSize="14px"
      >
        <FormattedMessage id="coaching.benchmarking.templates.button" />
      </Button>

      <Heading size="md" mt={10}>
        <FormattedMessage id="coaching.benchmarking.profiles.title" />
      </Heading>
      <ContentLoader status={profileStatus}>
        {profileData?.length ? (
          <AdvancedTable
            rows={profileData}
            keyExtractor={record => record.extId}
            columns={columns}
            sx={{ minHeight: '90%' }}
          />
        ) : (
          <Box mt={4}>
            <FormattedMessage id="coaching.benchmarking.profiles.noPrograms.label" />
          </Box>
        )}
        {isOpenWizardModal && (
          <FirstWizardModal onClose={() => setIsOpenWizardModal(false)} />
        )}
      </ContentLoader>
      {isOpenEditModal && selectedProfile && (
        <BenchmarkProfileEditModal
          onClose={() => setIsOpenEditModal(false)}
          title={selectedProfile.title}
          extId={selectedProfile.extId}
        />
      )}
      {isOpenLinkScenarioModal && selectedProfile && (
        <LinkScenariosModal
          extId={selectedProfile.extId}
          language={selectedProfile.language}
          onClose={() => setIsOpenLinkScenarioModal(false)}
          lockedScenarioExtIds={selectedProfile.linkedScenarios}
        />
      )}
      {wizard === 'wizard' && !isOpenWizardModal && (
        <FirstWizardModal onClose={() => navigate(`/coaching/benchmarking`)} />
      )}
    </>
  )
}
