import { useIntl } from '@repo/i18n'
import { useMemo } from 'react'

export const useFormRules = () => {
  const { formatMessage } = useIntl()

  const rules = useMemo(() => {
    const primitive = {
      required: formatMessage({ id: 'common.validation.required' }),
      minLength: (n: number) => ({
        value: n,
        message: formatMessage({ id: 'common.validation.minLength' }, { n }),
      }),
      maxLength: (n: number) => ({
        value: n,
        message: formatMessage({ id: 'common.validation.maxLength' }, { n }),
      }),
    }

    const nonEmptyStringRules = {
      required: primitive.required,
      validate: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        nonEmpty: async (value: any) =>
          !!value?.trim().length || formatMessage({ id: 'common.validation.required' }),
      },
    }

    const combinedOptional = {
      /** `255` max length */
      stringShort: { maxLength: primitive.maxLength(255) },
      /** `1000` max length */
      stringLong: { maxLength: primitive.maxLength(1000) },
      /** `5000` max length */
      string5k: { maxLength: primitive.maxLength(5000) },
      /** `10000` max length */
      string10k: { maxLength: primitive.maxLength(10000) },
      /** Custom max length */
      stringCustomLength: (n: number) => ({ maxLength: primitive.maxLength(n) }),
    }

    const combinedRequired = {
      /** Just required field */
      required: { required: primitive.required },
      /** `255` max length and required */
      stringShortRequired: {
        ...combinedOptional.stringShort,
        ...nonEmptyStringRules,
      },
      /** `1000` max length and required */
      stringLongRequired: {
        ...combinedOptional.stringLong,
        ...nonEmptyStringRules,
      },
      /** `5000` max length and required */
      string5kRequired: {
        ...combinedOptional.string5k,
        ...nonEmptyStringRules,
      },
      /** `10000` max length and required */
      string10kRequired: {
        ...combinedOptional.string10k,
        ...nonEmptyStringRules,
      },
      /** Custom max length and required */
      stringCustomLengthRequired: (n: number) => ({
        ...combinedOptional.stringCustomLength(n),
        ...nonEmptyStringRules,
      }),
    }

    const validateDuplicatedText = ({
      value,
      allValues,
      shouldMatchTwice = false,
      ignoredValue,
    }: {
      value: string
      allValues: Array<string>
      /**
       * If true, the value should match twice in the array to be considered a duplicate
       * This becomes useful while trying to see if a value is duplicated in a field array
       * e.g. if we want to provide ['joe', 'joe'] in a field array, we can set this to true
       */
      shouldMatchTwice: boolean
      /**
       * If provided, the value will not be considered a duplicate.
       * This is useful when we want to exclude the default value from the list of values
       */
      ignoredValue?: string
    }) => {
      // If the value is empty, we don't need to check for duplicates
      if (!value.trim().length) {
        return true
      }

      let matchedStrings = allValues.filter(
        s => s.trim().toLowerCase() === value.trim().toLowerCase()
      )

      if (ignoredValue) {
        matchedStrings = matchedStrings.filter(s => s !== ignoredValue)
      }

      const isDuplicate = matchedStrings.length > (shouldMatchTwice ? 1 : 0)

      if (isDuplicate) {
        return formatMessage({
          id: 'common.validation.alreadyExists',
        })
      }

      return true
    }

    return {
      /** functions to create rules */
      primitive,
      ...combinedOptional,
      ...combinedRequired,
      validateDuplicatedText,
    }
  }, [formatMessage])

  return rules
}
