import { Flex, Icon } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { IoCaretDownSharp, IoCaretUpSharp } from 'react-icons/io5'

import { type ColumnSortDir } from '../types'

interface SortIndicatorProps {
  sort?: ColumnSortDir
}

export const SortIndicator = ({ sort }: SortIndicatorProps) => (
  <Flex direction="column">
    <Icon
      fontSize={12}
      as={IoCaretUpSharp}
      mb={-1}
      color={sort === 'asc' ? colors.blue[500] : colors.blue[200]}
    />
    <Icon
      fontSize={12}
      as={IoCaretDownSharp}
      color={sort === 'desc' ? colors.blue[500] : colors.blue[200]}
    />
  </Flex>
)
