import {
  Box,
  Button,
  HStack,
  Icon,
  Stack,
  type SystemStyleObject,
  Text,
} from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, PageWrapper, SectionHeader, useModal } from '@repo/ui'
import { useEffect } from 'react'
import { AiFillInfoCircle } from 'react-icons/ai'
import { Link, useMatch, useNavigate, useSearchParams } from 'react-router-dom'

import { useAcceptSharedProgramModal } from '../coaching/program-list/accept-shared-program'
import { SuggestedTemplatesSection } from '../coaching/program-list/program-list/suggested-templates-section'
import { SharedProgramSummaryPopover } from '../coaching/program-list/shared-program-summary-popover'
import { AdvancedTable } from '../components/advanced-table'
import { type AdvancedTableColumns } from '../components/advanced-table/types'
import { AppHeaderTitle } from '../components/app-header-title'
import { CenteredSpinner } from '../components/centered-spinner'
import { ProgramStateBadge } from '../components/program-state-badge'
import { type ExtendedProgram } from '../types/api-types'
import { formatDateTime } from '../utils/dates'
import { sortDates, sortNumbers, sortStrings } from '../utils/sort-functions'
import { useOpenapiSWR } from '../utils/use-openapi-swr'
import { CreateProgramMenu } from './programs-page/create-program-menu'
import { useCreateProgramModal } from './programs-page/create-program-menu/use-create-program-modal'
import { ImageCell } from './programs-page/image-cell'
import { OptionsMenu } from './programs-page/options-menu'
import { PublishUnpublishProgramButton } from './programs-page/publish-unpublish-program-button'
import { SharedProgramsButton } from './programs-page/shared-programs-button'
import { useProgramsList } from './shared/api'

const columns: AdvancedTableColumns<ExtendedProgram> = [
  {
    key: 'img',
    title: null,
    field: 'imageUrl',
    customRender: record => <ImageCell url={record.imageUrl} />,
  },
  {
    key: 'name',
    title: <FormattedMessage id="common.name" />,
    field: 'title',
    sorter: (a, b) => sortStrings(a.title, b.title),
  },
  {
    key: 'status',
    title: <FormattedMessage id="coaching.programList.table.status" />,
    field: 'status',
    customRender: record => <ProgramStateBadge state={record.status} />,
    sorter: (a, b) => sortStrings(a.status, b.status),
  },
  {
    key: 'updatedAt',
    title: <FormattedMessage id="coaching.programList.table.update" />,
    field: 'updatedAt',
    customRender: record => formatDateTime(record.updatedAt),
    sorter: (a, b) => sortDates(a.updatedAt, b.updatedAt),
  },
  {
    key: 'trainingCount',
    title: <FormattedMessage id="coaching.programList.table.trainings" />,
    customRender: record => record.trainingsCount ?? 0,
    sorter: (a, b) => sortNumbers(a.trainingsCount, b.trainingsCount),
    align: 'end',
  },
  {
    key: 'language',
    title: <FormattedMessage id="coaching.program.templates.language" />,
    field: 'language',
    customRender: record => (
      <FormattedMessage id={`coaching.program.templates.language.${record.language}`} />
    ),
    sorter: (a, b) => sortStrings(a.language, b.language),
  },
  {
    key: 'sharedPrograms',
    title: '',
    customRender: record => <SharedProgramSummaryPopover program={record} />,
  },
  {
    key: 'actions',
    title: '',
    customRender: record => (
      <HStack justify="flex-end">
        <PublishUnpublishProgramButton program={record} />

        <Button as={Link} to={record.extId} variant="outline">
          <FormattedMessage id="common.open" />
        </Button>
        <OptionsMenu program={record} />
      </HStack>
    ),
  },
]

const tableStyle: SystemStyleObject = { minHeight: '500px' }
const keyExtractor = (program: ExtendedProgram) => program.extId

export const ProgramsPage = () => {
  const [createProgramModal, showCreateProgramModal] = useCreateProgramModal()

  const { data: sharedProgramsData, isValidating } = useOpenapiSWR('getSharedPrograms')
  const { data: coachingProgramsData, isValidating: isValidatingCoachingPrograms } =
    useProgramsList()

  const [sharedProgramWarningModal, showSharedProgramWarningModal] = useModal(
    ({ closeModal }) => (
      <Stack pb={4} display="flex" flexDirection="column" alignItems="center">
        <Text align="center">
          <FormattedMessage id="coaching.program.received.warning.modal.description" />
        </Text>
        <Text align="center">
          <FormattedMessage id="coaching.program.received.warning.modal.subtitle" />
        </Text>
        <Box display="flex" mt={6} justifyContent="center" mb={2}>
          <Button type="reset" px="28px !important" onClick={closeModal}>
            <FormattedMessage id="coaching.program.send.modal.requestsStatus.listItem.warningModal.button.close" />
          </Button>
        </Box>
      </Stack>
    ),
    {
      title: (
        <Stack display="flex" flexDirection="column" alignItems="center">
          <Icon as={AiFillInfoCircle} boxSize="40px" color={colors.blue[500]} />
          <Text mt={2}>
            <FormattedMessage id="common.warning" />
          </Text>
        </Stack>
      ),
    }
  )

  const isInitializingCoachingPrograms =
    !coachingProgramsData && isValidatingCoachingPrograms

  const isInitializingSharedPrograms = !sharedProgramsData && isValidating

  const isPageInitializing =
    isInitializingCoachingPrograms || isInitializingSharedPrograms

  const programs = coachingProgramsData ?? []

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const programName = searchParams.get('programName') ?? ''
  const senderOrganizationName = searchParams.get('organizationName') ?? ''
  const hasCreateParam = searchParams.get('create')

  const match = useMatch('/share/program/:sharedProgramId')
  const sharedProgramId = match?.params?.sharedProgramId

  useEffect(() => {
    if (hasCreateParam) {
      showCreateProgramModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [acceptSharedProgramModal, showAcceptSharedProgramModal] =
    useAcceptSharedProgramModal(programName, senderOrganizationName, sharedProgramId)

  const sharedProgram = sharedProgramsData?.find(
    program => program.extId === sharedProgramId
  )

  const isExpiredOrRejected =
    sharedProgram?.status === 'expired' || sharedProgram?.status === 'rejected'

  const isSharedProgramAccepted =
    !!sharedProgramId && sharedProgram?.status === 'accepted' && !!sharedProgram

  const isSharedProgramPending =
    !!sharedProgramId &&
    !isExpiredOrRejected &&
    !!sharedProgram &&
    sharedProgram?.status === 'pending'

  const isInvalidSharedProgram =
    (isExpiredOrRejected || !sharedProgram) && !!sharedProgramId

  useEffect(() => {
    if (isSharedProgramAccepted) {
      navigate('/training_programs')
    }
  }, [isSharedProgramAccepted, navigate])

  useEffect(() => {
    if (isSharedProgramPending) {
      showAcceptSharedProgramModal()
    }
  }, [isSharedProgramPending, showAcceptSharedProgramModal])

  useEffect(() => {
    if (!isPageInitializing && isInvalidSharedProgram) {
      showSharedProgramWarningModal()
    }
  }, [isPageInitializing, isInvalidSharedProgram, showSharedProgramWarningModal])

  if (isPageInitializing) {
    return <CenteredSpinner />
  }

  return (
    <PageWrapper
      pageActions={
        <HStack spacing={4} h="fit-content">
          <SharedProgramsButton />
          <CreateProgramMenu />
        </HStack>
      }
      sx={{ overflowY: 'auto' }}
    >
      {createProgramModal}
      <AppHeaderTitle formattedMessageId="coaching.programList.title" />

      {programs.length === 0 && <SuggestedTemplatesSection />}

      <SectionHeader
        titleKey="coaching.programList.table.title"
        subtitleKey="coaching.programList.table.subtitle"
        sx={{ mt: 6 }}
      />

      <AdvancedTable
        rows={programs}
        keyExtractor={keyExtractor}
        columns={columns}
        sx={tableStyle}
        emptyStateLabelKey="coaching.programList.table.emptyLabel"
      />
      {acceptSharedProgramModal}

      {sharedProgramWarningModal}
    </PageWrapper>
  )
}
