import { type Nil } from '@repo/utils'
import { isNil } from 'lodash-es'

export const sortDates = (a: Nil<string>, b: Nil<string>) => {
  if (isNil(a)) {
    return 1
  }

  if (isNil(b)) {
    return -1
  }

  return new Date(a).getTime() - new Date(b).getTime()
}

export const sortStrings = (a: Nil<string>, b: Nil<string>) => {
  if (isNil(a)) {
    return 1
  }

  if (isNil(b)) {
    return -1
  }

  return a.localeCompare(b)
}

export const sortNumbers = (a: Nil<number>, b: Nil<number>) => {
  if (isNil(a)) {
    return 1
  }

  if (isNil(b)) {
    return -1
  }

  return a - b
}
