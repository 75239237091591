import { type AdvancedTableColumn } from '../types'
import { columnDoesNotHaveCustomRender } from '../utils'

export const renderCellContent = <T>(column: AdvancedTableColumn<T>, record: T) => {
  // if there is no custom renderer, we can assume value can be rendered as string
  if (columnDoesNotHaveCustomRender(column)) {
    return `${record[column.field]}`
  }

  return column.customRender(record)
}
