import { Button } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { PageWrapper } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { RiAddFill } from 'react-icons/ri'
import { useParams } from 'react-router-dom'

import { AdvancedTable } from '../components/advanced-table'
import { type AdvancedTableColumns } from '../components/advanced-table/types'
import { AppHeaderTitle } from '../components/app-header-title'
import { CenteredSpinner } from '../components/centered-spinner'
import { type Training } from '../types/api-types'
import { sortNumbers, sortStrings } from '../utils/sort-functions'
import { useOpenapiSWR } from '../utils/use-openapi-swr'
import { TrainingRowActions } from './program-detail-page/training-row-actions'
import { useCreateTrainingModal } from './program-detail-page/use-create-training-modal'

const columns: AdvancedTableColumns<Training & { scenariosCount: number }> = [
  {
    key: 'name',
    title: 'Name',
    field: 'title',
    sorter: (a, b) => sortStrings(a.title, b.title),
  },
  {
    key: 'scenarioCount',
    title: 'Scenarios',
    customRender: record => record.scenariosCount ?? 0,
    sorter: (a, b) => sortNumbers(a.scenariosCount, b.scenariosCount),
    align: 'end',
  },
  {
    key: 'actions',
    title: '',
    customRender: record => <TrainingRowActions training={record} />,
    align: 'end',
  },
]

export const ProgramDetailPage = () => {
  const { programExtId } = useParams()

  assertExists(programExtId, 'programExtId')

  const [modal, showModal] = useCreateTrainingModal(programExtId)

  const { data: trainings, isLoading: isTrainingsLoading } = useOpenapiSWR(
    'getTrainings',
    { params: { programExtId } }
  )

  const { data: program } = useOpenapiSWR('getProgramByExtId', {
    params: { extId: programExtId },
  })

  if (!program) {
    return <CenteredSpinner />
  }

  const isReadonly = program?.status !== 'unpublished'

  return (
    <PageWrapper
      backLabelKey="program.goBack"
      backTo=".."
      sx={{ overflowY: 'auto' }}
      title={program.title}
      subtitleKey="program.page.subtitle"
      pageActions={
        <Button
          onClick={showModal}
          leftIcon={<RiAddFill size="1.25em" />}
          isDisabled={isReadonly}
        >
          <FormattedMessage id="training.create" />
        </Button>
      }
    >
      {modal}
      <AppHeaderTitle formattedMessageId="coaching.programList.title" />

      <AdvancedTable
        isLoading={isTrainingsLoading}
        rows={trainings ?? []}
        keyExtractor={record => record.extId}
        columns={columns}
        sx={{ minHeight: '500px' }}
        emptyStateLabelKey="training.table.emptyState"
      />
    </PageWrapper>
  )
}
