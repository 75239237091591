import { Box, Button, Flex, HStack, Icon } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { useMemo, useState } from 'react'
import { BsDownload } from 'react-icons/bs'
import { RiAddFill, RiErrorWarningLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom'

import { ContentLoader } from '../../builder/components/content-loader'
import { AdvancedTable } from '../../components/advanced-table'
import { type AdvancedTableColumns } from '../../components/advanced-table/types'
import { formatDateTime } from '../../utils/dates'
import { sortDates, sortStrings } from '../../utils/sort-functions'
import { type UserToken } from '../types/user-token'
import { useCohortParticipants } from '../use-cohort-participants'
import { useCohorts } from '../use-cohorts'
import { useParticipants } from '../use-participants'
import { CohortAddTokensModal } from './cohort-add-tokens-modal'
import { CohortParticipantRowMenu } from './cohort-participant-row-menu'

export const CohortParticipants = () => {
  const { formatMessage } = useIntl()
  const { id: cohortId } = useParams<{ id: string }>() as {
    id: string
  }

  const { useParticipantsList, downloadAvailableTokens, addTokens } =
    useCohortParticipants()

  const { useCohort } = useCohorts()
  const { removeUsers } = useParticipants()

  const { data: cohortData, refresh: refreshCohortDetails } = useCohort(cohortId)
  const { data, status, refresh: refreshParticipantsList } = useParticipantsList(cohortId)
  const [isOpenAddTokensModal, setIsOpenAddTokensModal] = useState(false)
  const isTokenCohort = cohortData?.type === 'token'

  const removeUsersFromOrganization = useMemo(
    () => async (userIds: Array<string>) => {
      try {
        await removeUsers(userIds)
        refreshParticipantsList()
        refreshCohortDetails()
        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
      } catch (err) {
        showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
      }
    },
    [removeUsers, refreshParticipantsList, refreshCohortDetails]
  )

  const downloadTokenList = async () => {
    try {
      await downloadAvailableTokens(cohortId)
      showToast({ messageKey: 'general.success.downloading.data', status: 'success' })
    } catch (e) {
      showToast({ messageKey: 'general.error.downloading.data', status: 'error' })
    }
  }

  const rowLabels = useMemo(
    () => ({
      tokenState: {
        available: formatMessage({
          id: 'cohort.details.tokens.list.state.available',
        }),
        used: formatMessage({
          id: 'cohort.details.tokens.list.state.used',
        }),
        reserved: formatMessage({
          id: 'cohort.details.tokens.list.state.reserved',
        }),
      },
    }),
    [formatMessage]
  )

  const columns: AdvancedTableColumns<UserToken> = [
    {
      key: 'token',
      title: <FormattedMessage id="cohort.details.tokens.list.tokens" />,
      field: 'token',
      sorter: (a, b) => sortStrings(a.token, b.token),
    },
    {
      key: 'createdAt',
      title: <FormattedMessage id="cohort.details.tokens.list.createdAt" />,
      field: 'createdAt',
      sorter: (a, b) => sortDates(a.createdAt, b.createdAt),
      customRender: record => formatDateTime(record.createdAt),
    },
    {
      key: 'status',
      title: <FormattedMessage id="cohort.details.tokens.list.state" />,
      customRender: record => (
        <Box
          textColor={record.status === 'AVAILABLE' ? 'indicator.green-dark' : undefined}
        >
          {rowLabels.tokenState[record.status.toLowerCase()]}
        </Box>
      ),
    },

    {
      key: 'actions',
      title: '',
      customRender: record => (
        <CohortParticipantRowMenu
          deleteParticipant={removeUsersFromOrganization}
          userId={record.userId}
          cohortId={cohortId}
        />
      ),
    },
  ]

  return (
    <>
      <Flex pb={4} justify="right">
        <HStack spacing={4} h="fit-content">
          <Button leftIcon={<BsDownload />} variant="link" onClick={downloadTokenList}>
            <FormattedMessage id="cohorts.list.downloadTokens" />
          </Button>
          <Button
            onClick={() => setIsOpenAddTokensModal(true)}
            leftIcon={<RiAddFill size="1.25em" />}
            iconSpacing="1"
          >
            <FormattedMessage id="cohort.details.button.addTokens" />
          </Button>
        </HStack>
      </Flex>
      <ContentLoader status={status}>
        {status === 'loaded' && data?.length ? (
          <AdvancedTable
            rows={data}
            keyExtractor={record => record.userId}
            columns={columns}
            sx={{ minHeight: '90%' }}
          />
        ) : (
          <Box display="flex" alignItems="center" maxW="800px" mt={8}>
            <Box h="30px" w="30px" mr={4}>
              <Icon as={RiErrorWarningLine} />
            </Box>
            <FormattedMessage
              id={
                isTokenCohort
                  ? 'cohort.programs.list.noParticipants'
                  : 'cohort.programs.list.no.email.participants'
              }
            />
          </Box>
        )}
      </ContentLoader>

      <CohortAddTokensModal
        addTokens={addTokens}
        refreshCohortDetails={refreshCohortDetails}
        refreshParticipantsList={refreshParticipantsList}
        cohortId={cohortId}
        isOpen={isOpenAddTokensModal}
        onClose={() => setIsOpenAddTokensModal(false)}
      />
    </>
  )
}
