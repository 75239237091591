import { Badge, Box, HStack, Icon, Image, Stack, Text, Tooltip } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, PillTag } from '@repo/ui'
import { type ReactNode } from 'react'
import { IoIosInformationCircle } from 'react-icons/io'

import AiBuilderImg from '../../../assets/ai-builder.png'
import ManualBuilderImg from '../../../assets/manual-builder.png'
import RealtimeBuilderImg from '../../../assets/realtime-builder.png'
import { BaseCard } from '../../../components/card-grid/shared/base-card'

const GradientOverlay = () => (
  <Box
    position="absolute"
    inset={0}
    bg="linear-gradient(153.85deg, #FFFFFF 12.75%, #FFF1FE 32%, rgba(227, 219, 246, 0.841045) 51.91%, rgba(184, 202, 237, 0.688551) 71.18%, rgba(33, 89, 199, 0.03) 99.41%)"
    opacity={0.3}
  />
)

type Mode = 'ai' | 'manual' | 'realtimeOld'

const cardConfigs: Record<
  Mode,
  {
    tagKey?: I18nKey
    image: string
    hasAi: boolean
  }
> = {
  ai: {
    tagKey: 'common.beta',
    image: AiBuilderImg,
    hasAi: true,
  },
  manual: {
    image: ManualBuilderImg,
    hasAi: false,
  },
  realtimeOld: {
    tagKey: 'common.beta',
    image: RealtimeBuilderImg,
    hasAi: true,
  },
}

export const BuilderModeCard = ({
  mode,
  onClick,
  isDisabled,
  tooltip,
  showInfo,
}: {
  mode: Mode
  onClick?: () => void
  isDisabled?: boolean
  tooltip?: ReactNode
  showInfo?: boolean
}) => {
  const { hasAi, image, tagKey } = cardConfigs[mode]

  return (
    <Tooltip label={tooltip} isDisabled={!tooltip}>
      <BaseCard
        flex={1}
        textAlign="center"
        p={4}
        onClick={isDisabled ? undefined : onClick}
        cursor={!isDisabled ? 'pointer' : 'not-allowed'}
      >
        <Box opacity={isDisabled ? 0.5 : 1}>
          {hasAi && isDisabled && <GradientOverlay />}

          {tagKey && (
            <PillTag
              sx={{
                zIndex: 2,
                bgColor: colors.blue[500],
                color: 'white',
                pos: 'absolute',
                top: 4,
                right: 0,
              }}
              text={<FormattedMessage id={tagKey} />}
              cut="right"
            />
          )}

          <Stack zIndex={1} gap={4}>
            <Image height="140px" objectFit="contain" src={image} />

            <Badge
              colorScheme="blue"
              variant="subtle"
              alignSelf="center"
              visibility={hasAi ? 'visible' : 'hidden'}
            >
              <FormattedMessage id="scenario.createNewModal.aiTag" />
            </Badge>

            <Text fontWeight={500} fontSize={18}>
              <FormattedMessage
                id={`scenario.createNewModal.${mode}.title` satisfies I18nKey}
              />
            </Text>

            <Text fontSize={14}>
              <FormattedMessage
                id={`scenario.createNewModal.${mode}.description` satisfies I18nKey}
              />
            </Text>
          </Stack>
        </Box>
        {showInfo && (
          <HStack gap={1} justifyContent="center" mt={4}>
            <Icon
              as={IoIosInformationCircle}
              color={colors.blue[500]}
              w="18px"
              h="18px"
            />
            <Text color={colors.blue[500]}>
              <FormattedMessage id="scenario.createNewModal.info.text" />
            </Text>
          </HStack>
        )}
      </BaseCard>
    </Tooltip>
  )
}
