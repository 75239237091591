import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { type ReactNode, useState } from 'react'
import { RiMoreFill } from 'react-icons/ri'

import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { AddCohortParticipantModal } from './add-cohort-participant-modal'
import { CohortParticipantStatusModal } from './cohort-participant-status-modal'

interface CohortParticipantRowMenuProps {
  deleteParticipant: (userIds: Array<string>) => Promise<void>
  updateParticipantsStatus?: (
    userId: string,
    isDisabled: boolean,
    cohortId: string
  ) => Promise<void>
  removeParticipantFromCohort?: (userId: Array<string>, cohortId: string) => Promise<void>
  reAddEmailParticipant?: (userEmail: string, cohortId: string) => Promise<void>
  modalLabel?: ReactNode
  modalTitle?: ReactNode
  modalDescription?: ReactNode
  userId: string
  userEmail?: string
  isDisabled?: boolean
  cohortId: string
  removed?: boolean
  isCohortEmail?: boolean
}

export const CohortParticipantRowMenu = ({
  deleteParticipant,
  updateParticipantsStatus,
  removeParticipantFromCohort,
  reAddEmailParticipant,
  modalLabel,
  modalTitle,
  modalDescription,
  userId,
  userEmail,
  isDisabled,
  cohortId,
  removed,
  isCohortEmail,
}: CohortParticipantRowMenuProps) => {
  const { formatMessage } = useIntl()
  const cohortParticipantStatusModal = useDisclosure()
  const addCohortParticipantModal = useDisclosure()
  const [isRemoveFromCohortButtonLoading, setIsRemoveFromCohortButtonLoading] =
    useState(false)

  const [
    isDeleteFromOrganizationButtonLoading,
    setIsDeleteFromOrganizationButtonLoading,
  ] = useState(false)

  const hasModalDetails = modalLabel && modalTitle && modalDescription

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={IconButton}
            variant="ghost"
            icon={<RiMoreFill />}
            aria-label="menu"
            isActive={isOpen}
          />
          <MenuList>
            {cohortId !== null && isDisabled !== undefined && (
              <MenuItem onClick={cohortParticipantStatusModal.onOpen}>
                <Text>
                  {isDisabled ? (
                    <FormattedMessage id="cohort.participant.updateStatus.confirmModal.enable" />
                  ) : (
                    <FormattedMessage id="cohort.participant.updateStatus.confirmModal.disable" />
                  )}
                </Text>
                {cohortParticipantStatusModal.isOpen && (
                  <CohortParticipantStatusModal
                    isDisabled={isDisabled}
                    isOpen={cohortParticipantStatusModal.isOpen}
                    onClose={cohortParticipantStatusModal.onClose}
                    onConfirm={async () => {
                      const updatedStatus = !isDisabled

                      if (updateParticipantsStatus) {
                        await updateParticipantsStatus(userId, updatedStatus, cohortId)
                      }
                    }}
                  />
                )}
              </MenuItem>
            )}
            {cohortId !== null && reAddEmailParticipant && removed && userEmail && (
              <MenuItem onClick={addCohortParticipantModal.onOpen}>
                <Text>
                  <FormattedMessage id="cohort.participant.reAdd.participant.confirmModal.button" />
                </Text>

                <AddCohortParticipantModal
                  isOpen={addCohortParticipantModal.isOpen}
                  onClose={addCohortParticipantModal.onClose}
                  onConfirm={async () => {
                    if (reAddEmailParticipant) {
                      await reAddEmailParticipant(userEmail, cohortId)
                    }
                  }}
                />
              </MenuItem>
            )}
            {removeParticipantFromCohort && !removed && hasModalDetails && (
              <InterceptAndConfirm
                onConfirm={async () => {
                  setIsRemoveFromCohortButtonLoading(true)
                  await removeParticipantFromCohort([userId], cohortId)
                  setIsRemoveFromCohortButtonLoading(false)
                }}
                title={modalTitle}
                description={modalDescription}
                ctaLabel={formatMessage({
                  id: 'cohort.participant.delete.confirmModal.cta',
                })}
                isButtonLoading={isRemoveFromCohortButtonLoading}
              >
                {({ openModal }) => (
                  <MenuItem onClick={openModal}>
                    <Text>{modalLabel}</Text>
                  </MenuItem>
                )}
              </InterceptAndConfirm>
            )}

            {!isCohortEmail && (
              <InterceptAndConfirm
                onConfirm={async () => {
                  setIsDeleteFromOrganizationButtonLoading(true)
                  await deleteParticipant([userId])
                  setIsDeleteFromOrganizationButtonLoading(false)
                }}
                title={formatMessage({ id: 'cohort.participant.delete.modal.title' })}
                description={formatMessage({
                  id: 'cohort.participant.delete.modal.description',
                })}
                ctaLabel={formatMessage({
                  id: 'common.delete',
                })}
                size="lg"
                isButtonLoading={isDeleteFromOrganizationButtonLoading}
              >
                {({ openModal }) => (
                  <MenuItem color="red.800" onClick={openModal}>
                    <Text>
                      <FormattedMessage id="common.delete" />
                    </Text>
                  </MenuItem>
                )}
              </InterceptAndConfirm>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  )
}
