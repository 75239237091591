import { Thead } from '@chakra-ui/react'
import { type PropsWithChildren } from 'react'

export const StyledThead = ({
  shadowVisible,
  children,
}: PropsWithChildren<{ shadowVisible: boolean }>) => (
  <Thead
    position="sticky"
    top={0}
    bg="white"
    zIndex="1"
    boxShadow={shadowVisible ? '0 2px 4px 0 rgba(0, 0, 0, 0.1)' : 'none'}
    transition="box-shadow 0.2s"
  >
    {children}
  </Thead>
)
