import { Button, FormControl, Heading, HStack, IconButton, Stack } from '@chakra-ui/react'
import { type ApiSchemas, AVAILABLE_PROGRAM_LANGUAGES } from '@repo/api'
import { FormattedMessage, languageLabels, useIntl } from '@repo/i18n'
import { colors, CustomFormLabel, RHF, useFormRules } from '@repo/ui'
import { uniqueId } from 'lodash-es'
import { useFieldArray, useForm } from 'react-hook-form'
import { FiMinusCircle } from 'react-icons/fi'

import { RHFImageUploader } from '../../../components/rhf-image-uploader'
import { type ExerciseFormValues } from '../../exercise-form-values'

const MAX_ITEM_COUNT = 6

const exerciseTypes: Array<ApiSchemas['exerciseType']> = [
  'facialExpressions',
  'speakingSpeed',
  'understandability',
  'pauses',
  'socialFocus',
  'positiveLanguage',
  'sentenceLength',
  'adjectives',
]

export const useExerciseForm = (defaultValues?: ExerciseFormValues) => {
  const form = useForm<ExerciseFormValues>({
    defaultValues: defaultValues ?? {
      durationInMinutes: 1,
      preparationItems: [{ id: uniqueId(), text: '' }],
    },
    mode: 'onTouched',
  })

  const { formatMessage } = useIntl()
  const rules = useFormRules()

  const { control, setError, clearErrors } = form

  const preparationItems = useFieldArray<ExerciseFormValues>({
    name: 'preparationItems',
    control,
    rules: { required: true, minLength: 1 },
  })

  const formFieldsJsx = (
    <Stack gap={6}>
      <HStack gap={6} alignItems="flex-start">
        <Stack flex={1}>
          <RHF.Input
            control={control}
            name="title"
            labelKey="exercise.form.title.label"
            rules={rules.stringShortRequired}
            componentProps={{
              placeholder: formatMessage({ id: 'exercise.form.title.placeholder' }),
            }}
          />
          <RHF.TextArea
            control={control}
            name="shortDescription"
            labelKey="exercise.form.shortDescription.label"
            componentProps={{
              placeholder: formatMessage({
                id: 'exercise.form.shortDescription.placeholder',
              }),
            }}
            rules={rules.stringLongRequired}
          />
        </Stack>
      </HStack>
      <RHFImageUploader
        control={control}
        name="imageUrl"
        labelKey="exercise.form.image.title"
        rules={rules.required}
        setError={setError}
        clearErrors={clearErrors}
      />

      <HStack alignItems="flex-start">
        <RHF.Select
          control={control}
          name="type"
          labelKey="exercise.form.type.label"
          rules={rules.required}
          componentProps={{
            placeholder: formatMessage({ id: 'exercise.form.type.placeholder' }),
            children: exerciseTypes.map(key => (
              <option key={key} value={key}>
                {formatMessage({ id: `exercise.type.${key}` satisfies I18nKey })}
              </option>
            )),
          }}
        />

        <RHF.NumberInput
          control={control}
          labelKey="exercise.form.durationInMinutes.label"
          name="durationInMinutes"
          rules={rules.required}
          componentProps={{
            min: 1,
            max: 300,
            precision: 0,
            rightAddon: <FormattedMessage id="exercise.form.durationInMinutes.unit" />,
          }}
        />

        <RHF.Select
          control={control}
          name="language"
          labelKey="exercise.form.language.label"
          rules={rules.required}
          componentProps={{
            placeholder: formatMessage({ id: 'exercise.language.placeholder' }),
            children: AVAILABLE_PROGRAM_LANGUAGES.map(key => (
              <option key={key} value={key}>
                {languageLabels[key]}
              </option>
            )),
          }}
        />
      </HStack>

      <Heading size="md" color={colors.blue[500]}>
        <FormattedMessage id="exercise.form.actualExercise.heading" />
      </Heading>
      <RHF.TextArea
        control={control}
        name="description"
        labelKey="exercise.form.introduction.title"
        componentProps={{
          placeholder: formatMessage({
            id: 'exercise.form.description.placeholder',
          }),
        }}
        rules={rules.string10kRequired}
      />

      <FormControl isRequired>
        <CustomFormLabel htmlFor="preparationItems">
          <FormattedMessage id="exercise.form.preparation.title" />
        </CustomFormLabel>
        <Stack>
          {preparationItems.fields.map((field, index) => (
            <HStack key={field.id} align="flex-start">
              <RHF.Input
                hideLabel
                control={control}
                name={`preparationItems.${index}.text`}
                rules={rules.stringShortRequired}
                componentProps={{
                  placeholder: formatMessage({
                    id: 'exercise.form.preparation.placeholder',
                  }),
                }}
              />
              <IconButton
                isDisabled={preparationItems.fields.length === 1}
                variant="ghost"
                colorScheme="blackAlpha"
                fontSize={24}
                aria-label=""
                onClick={() => preparationItems.remove(index)}
                icon={<FiMinusCircle />}
              />
            </HStack>
          ))}
          {preparationItems.fields.length < MAX_ITEM_COUNT && (
            <Button
              alignSelf="flex-start"
              variant="ghost"
              onClick={() => preparationItems.append({ id: uniqueId(), text: '' })}
            >
              <FormattedMessage id="exercise.form.preparation.add" />
            </Button>
          )}
        </Stack>
      </FormControl>

      <RHF.Markdown
        control={control}
        name="execution"
        labelKey="exercise.form.execution.title"
        rules={rules.string10kRequired}
        componentProps={{
          excluded: /(live|edit|preview|fullscreen|code|codeBlock|image|help)/,
          textareaProps: {
            placeholder: formatMessage({ id: 'exercise.form.execution.placeholder' }),
          },
        }}
      />
    </Stack>
  )

  return [form, formFieldsJsx] as const
}
