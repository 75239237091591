import { Button, HStack, Image, Tag } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { FormattedMessage } from '@repo/i18n'
import { PageHeader } from '@repo/ui'
import { useMemo } from 'react'
import { BsUpload } from 'react-icons/bs'
import { RiAddFill } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import { AdvancedTable } from '../../components/advanced-table'
import { type AdvancedTableColumns } from '../../components/advanced-table/types'
import { AppHeaderTitle } from '../../components/app-header-title'
import { CenteredSpinner } from '../../components/centered-spinner'
import { PageList } from '../../containers/page-list'
import { useGoogleStorageAsset } from '../../hooks/use-google-storage-asset'
import { useProgramsList } from '../../new-program-builder/shared/api'
import { type ExerciseWithConnectedPrograms } from '../../types/api-types'
import { sortStrings } from '../../utils/sort-functions'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'
import { ConnectExerciseButton } from './exercise-list/connect-exercise-button'
import { useCreateExerciseModal } from './exercise-list/use-create-exercise-modal'
import { OptionsMenu } from './options-menu'

const flexColumnCss = css`
  display: flex;
  flex-direction: column;
`

const ImageRender = ({ imageUrl }) => {
  const imageHttpUrl = useGoogleStorageAsset(imageUrl)

  return <Image w="100px" src={imageHttpUrl} />
}

export const ExerciseList = () => {
  const { data } = useOpenapiSWR('getExercises')
  const isLoading = data === undefined
  const exercises = useMemo(() => data ?? [], [data])
  const { data: trainingPrograms } = useProgramsList()
  const [createExerciseModal, showCreateExerciseModal] = useCreateExerciseModal()

  const columns = useMemo<AdvancedTableColumns<ExerciseWithConnectedPrograms>>(
    () => [
      {
        key: 'imageUrl',
        title: <FormattedMessage id="coaching.exerciseList.table.image" />,
        field: 'imageUrl',
        customRender: record => <ImageRender imageUrl={record.imageUrl} />,
      },
      {
        key: 'title',
        title: <FormattedMessage id="coaching.exerciseList.table.title" />,
        field: 'title',
        sorter: (a, b) => sortStrings(a.title, b.title),
      },
      {
        key: 'type',
        title: <FormattedMessage id="coaching.exerciseList.table.variable" />,
        field: 'type',
        sorter: (a, b) => sortStrings(a.type, b.type),
        customRender: record => (
          <FormattedMessage id={`exercise.type.${record.type}` satisfies I18nKey} />
        ),
      },
      {
        key: 'linkedPrograms',
        title: <FormattedMessage id="coaching.exerciseList.table.program" />,
        customRender: record => {
          const linkedPrograms = trainingPrograms?.filter(program =>
            record.programExtIds.includes(program.extId)
          )

          return (
            <HStack spacing={2} flexWrap="wrap">
              {linkedPrograms?.map(program => (
                <Tag textAlign="center" key={program.extId} size="sm" colorScheme="teal">
                  {program.title}
                </Tag>
              ))}
            </HStack>
          )
        },
      },
      {
        key: 'actions',
        title: '',
        customRender: record => (
          <HStack justify="flex-end">
            <ConnectExerciseButton exercise={record} />
            <OptionsMenu exercise={record} />
          </HStack>
        ),
      },
    ],
    [trainingPrograms]
  )

  return (
    <PageList css={flexColumnCss}>
      {createExerciseModal}
      <AppHeaderTitle formattedMessageId="coaching.exerciseList.title" />
      <PageHeader
        buttons={
          <HStack spacing={4} h="fit-content">
            <Button variant="link" leftIcon={<BsUpload />} as={Link} to="import">
              <FormattedMessage id="coaching.programList.button.import" />
            </Button>
            <Button
              leftIcon={<RiAddFill size="1.25em" />}
              iconSpacing="1"
              onClick={showCreateExerciseModal}
            >
              <FormattedMessage id="coaching.programList.button.create" />
            </Button>
          </HStack>
        }
      />

      {!!exercises.length && (
        <AdvancedTable
          rows={exercises}
          keyExtractor={record => record.extId}
          columns={columns}
          sx={{ minHeight: '90%' }}
        />
      )}
      {isLoading && <CenteredSpinner />}
    </PageList>
  )
}
