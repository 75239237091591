import { FormattedMessage } from '@repo/i18n'
import { useMemo } from 'react'

import { AdvancedTable } from '../../../components/advanced-table'
import { type AdvancedTableColumns } from '../../../components/advanced-table/types'
import { sortNumbers, sortStrings } from '../../../utils/sort-functions'
import { useDashboardContext } from '../../shared/dashboard-context'
import { type GeneralScore } from '../../shared/types'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { NoResultsCell } from './shared/no-results-cell'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

const columns: AdvancedTableColumns<{
  id: string
  cohortName: string
  overallScore: GeneralScore
  isHiddenMetric: boolean
}> = [
  {
    key: 'cohortName',
    title: (
      <FormattedMessage id="dashboard.cards.cohortPerformance.details.cohortName.table.header" />
    ),
    field: 'cohortName',
    sorter: (a, b) => sortStrings(a.cohortName, b.cohortName),
  },
  {
    key: 'overallScore',
    title: (
      <FormattedMessage id="dashboard.cards.cohortPerformance.details.overallScore.table.header" />
    ),
    field: 'overallScore',
    sorter: ({ overallScore: firstScore }, { overallScore: secondScore }) =>
      sortNumbers(firstScore, secondScore),
    customRender: ({ overallScore, isHiddenMetric }) =>
      overallScore === null ? (
        <NoResultsCell isHiddenMetric={isHiddenMetric} />
      ) : (
        `${overallScore}%`
      ),
    align: 'end',
  },
]

export const CohortPerformanceCardDetails = () => {
  const { dataSlot } = useDashboardCardData('cohortPerformance')

  const { pageState } = useDashboardContext()

  const tableRows = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    const rows = data.cohortNames.map((cohortName, index) => ({
      id: data.cohortExtIds[index]!,
      cohortName,
      overallScore: data.overallScore[index]!,
      isHiddenMetric: data.metricsHidden[index]!,
    }))

    const sortedRows = rows.toSorted((a, b) => b.overallScore - a.overallScore)

    return sortedRows
  }, [dataSlot])

  return (
    <DetailPageWrapper cardId="cohortPerformance">
      <AdvancedTable
        rows={tableRows}
        keyExtractor={record => record.id}
        columns={columns}
        isLoading={pageState === 'loading'}
      />
    </DetailPageWrapper>
  )
}
