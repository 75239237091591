import { Box, Button, Checkbox, HStack, Icon, Input, Stack, Text } from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { colors, showToast } from '@repo/ui'
import { uniq } from 'lodash-es'
import { useMemo, useState } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { RiErrorWarningLine } from 'react-icons/ri'

import { ContentLoader } from '../../builder/components/content-loader'
import { ActionButtonContainer } from '../../coaching-benchmark-profiles/components/action-button-container'
import { AdvancedTable } from '../../components/advanced-table'
import { type AdvancedTableColumns } from '../../components/advanced-table/types'
import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { sortStrings } from '../../utils/sort-functions'
import { isInvitationExpired } from '../helpers/utils'
import {
  type EmailParticipantStatus,
  type UserParticipant,
} from '../types/cohort-email-participants'
import { useCohortParticipants } from '../use-cohort-participants'
import { useParticipants } from '../use-participants'
import { CohortParticipantRowMenu } from './cohort-participant-row-menu'

interface KeyAndColor {
  messageKey: I18nKey
  statusColor: string
}
const getMessageKeyAndColor = (
  status: EmailParticipantStatus,
  lastInvitedDate: string
): KeyAndColor => {
  switch (status) {
    case 'ACTIVATED':
      return {
        messageKey: 'cohort.creation.email.participant.row.activated.status',
        statusColor: colors.green[500],
      }
    case 'REMOVED':
      return {
        messageKey: 'cohort.creation.email.participant.row.removed.status',
        statusColor: colors.red[500],
      }
    case 'DISABLED':
      return {
        messageKey: 'cohort.creation.email.participant.row.disabled.status',
        statusColor: colors.gray[500],
      }

    default:
      if (isInvitationExpired(lastInvitedDate)) {
        return {
          messageKey: 'cohort.creation.email.participant.row.invitation.expired.status',
          statusColor: colors.blue[500],
        }
      }

      return {
        messageKey: 'cohort.creation.email.participant.row.not.activated.status',
        statusColor: colors.gray[500],
      }
  }
}

export const UserTable = () => {
  const { formatMessage } = useIntl()
  const [selectedParticipants, setSelectedParticipants] = useState<Array<string>>([])

  const { removeParticipants, updateParticipantsActivationStatus, addEmails } =
    useCohortParticipants()
  // Do not delete. This is going to be used for later when the select all checkbox is enable again
  // const [isCheckAll, setIsCheckAll] = useState(false)

  const [deleteAllInputValue, setDeleteAllInputValue] = useState('')
  const [isDeleteAllButtonLoading, setIsDeleteAllButtonLoading] = useState(false)

  const { useEmailUsersList, removeUsers } = useParticipants()
  const {
    data: participantsData,
    status,
    refresh: refreshUserParticipantsList,
  } = useEmailUsersList()

  const deleteParticipant = useMemo(
    () => async (userIds: Array<string>) => {
      try {
        await removeUsers(userIds)
        refreshUserParticipantsList()
        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
        setSelectedParticipants(prevSelected =>
          prevSelected.filter(id => !userIds.includes(id))
        )
      } catch (err) {
        showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
      }
    },
    [removeUsers, refreshUserParticipantsList]
  )

  const removeParticipantFromCohort = useMemo(
    () => async (userIds: Array<string>, cohortId: string) => {
      try {
        await removeParticipants(cohortId, userIds)
        refreshUserParticipantsList()
        showToast({ messageKey: 'common.alert.deleted', status: 'success' })
      } catch (err) {
        showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
      }
    },
    [removeParticipants, refreshUserParticipantsList]
  )

  const addEmailParticipant = useMemo(
    () => async (userEmail: string, cohortId: string) => {
      try {
        // Language we pass here is not important
        await addEmails([userEmail], cohortId, 'en-US')
        refreshUserParticipantsList()
        showToast({
          messageKey: 'cohort.participant.updateStatus.confirmModal.success',
          status: 'success',
        })
      } catch (err) {
        showToast({ messageKey: 'general.error.saving.data', status: 'error' })
      }
    },
    [addEmails, refreshUserParticipantsList]
  )

  const updateParticipantsStatus = useMemo(
    () => async (userId: string, isDisabled: boolean, cohortId: string) => {
      try {
        await updateParticipantsActivationStatus(cohortId, userId, isDisabled)
        refreshUserParticipantsList()
        showToast({
          messageKey: 'cohort.participant.updateStatus.confirmModal.success',
          status: 'success',
        })
      } catch (err) {
        showToast({ messageKey: 'general.error.saving.data', status: 'error' })
      }
    },
    [updateParticipantsActivationStatus, refreshUserParticipantsList]
  )

  const handleToggle = e => {
    const { value, checked } = e.target

    if (checked) {
      setSelectedParticipants(prev => [...prev, value])
    } else {
      setSelectedParticipants(prev => prev.filter(x => x !== value))
    }
  }

  // Do not delete. This is going to be used for later when the select all checkbox is enable again
  /* const handleSelectAll = () => {
    const isChecked = !isCheckAll

    if (status === 'loaded' && participantsData?.length) {
      setSelectedParticipants(
        isChecked ? participantsData.map(participant => participant.userId) : []
      )

      setIsCheckAll(isChecked)
    }
  } */

  const columns: AdvancedTableColumns<UserParticipant> = [
    {
      key: 'selectAll',
      title: '',
      customRender: record => (
        <Checkbox
          value={record.userId}
          onChange={handleToggle}
          isChecked={selectedParticipants.includes(record.userId)}
        />
      ),
    },
    {
      key: 'email',
      title: <FormattedMessage id="participant.cohorts.list.table.name" />,
      field: 'email',
      sorter: (a, b) => sortStrings(a.email, b.email),
      customRender: record => (
        <Box>
          {record.email}
          {record.profile && (
            <Box fontSize="sm" color={colors.gray[500]} mt={1}>
              {record.profile.firstName} {record.profile.lastName}
            </Box>
          )}
        </Box>
      ),
    },
    {
      key: 'cohortName',
      title: <FormattedMessage id="participant.cohorts.list.table.cohort" />,
      field: 'cohortName',
      customRender: record => <Text>{record.cohortName ?? ''}</Text>,
      sorter: (a, b) => sortStrings(a.cohortName, b.cohortName),
    },
    {
      key: 'status',
      title: <FormattedMessage id="participant.cohorts.list.table.state" />,
      field: 'state',
      sorter: (a, b) => {
        const { messageKey: messageKeyA } = getMessageKeyAndColor(
          a.state,
          a.lastInvitedAt
        )

        const { messageKey: messageKeyB } = getMessageKeyAndColor(
          b.state,
          b.lastInvitedAt
        )

        const labelA = formatMessage({ id: messageKeyA })
        const labelB = formatMessage({ id: messageKeyB })

        return sortStrings(labelA, labelB)
      },
      customRender: record => {
        const { messageKey, statusColor } = getMessageKeyAndColor(
          record.state,
          record.lastInvitedAt
        )

        return (
          <HStack gap={1} alignContent="center">
            <Box w="12px" h="12px" borderRadius="50%" bg={statusColor} />
            <FormattedMessage id={messageKey} />
          </HStack>
        )
      },
    },
    {
      key: 'actions',
      title: '',
      customRender: record => (
        <CohortParticipantRowMenu
          deleteParticipant={deleteParticipant}
          removeParticipantFromCohort={removeParticipantFromCohort}
          updateParticipantsStatus={updateParticipantsStatus}
          reAddEmailParticipant={addEmailParticipant}
          isDisabled={record.isDisabled}
          modalTitle={formatMessage({
            id: 'cohort.creation.email.participants.remove.participant.modal.title',
          })}
          modalLabel={formatMessage({
            id: 'cohort.participant.delete.confirmModal.remove.from.cohort',
          })}
          modalDescription={formatMessage({
            id: 'cohort.creation.email.participants.remove.participant.modal.description',
          })}
          userId={record.userId}
          userEmail={record.email}
          cohortId={record.cohortExtId}
          removed={record.state === 'REMOVED'}
        />
      ),
    },
  ]

  const deleteAllAction = formatMessage({
    id: 'cohort.creation.email.participants.remove.all.participant.modal.description.action',
  })

  return (
    <>
      <Text>
        <FormattedMessage id="participant.cohorts.list.table.text" />
      </Text>
      <ContentLoader status={status}>
        {status === 'loaded' && participantsData?.length ? (
          <AdvancedTable
            rows={participantsData}
            keyExtractor={record => record.participantId}
            columns={columns}
            sx={{ minHeight: '90%' }}
          />
        ) : (
          <Box display="flex" alignItems="center" pt={8}>
            <Icon mr={4} as={RiErrorWarningLine} />
            <Text>
              <FormattedMessage id="cohort.creation.email.participants.empty.table" />
            </Text>
          </Box>
        )}
      </ContentLoader>
      <ActionButtonContainer>
        <HStack gap={4}>
          <InterceptAndConfirm
            onCancel={() => setDeleteAllInputValue('')}
            onConfirm={async () => {
              try {
                setIsDeleteAllButtonLoading(true)
                const uniqueParticipants = uniq(selectedParticipants)

                await removeUsers(uniqueParticipants)
                refreshUserParticipantsList()
                setSelectedParticipants([])
                setDeleteAllInputValue('')
                setIsDeleteAllButtonLoading(false)
                showToast({
                  messageKey: 'common.alert.deleted',
                  status: 'success',
                })
              } catch (e) {
                showToast({ messageKey: 'general.error.deleting.data', status: 'error' })
              }
            }}
            title={formatMessage({
              id: 'cohort.creation.email.participants.remove.all.participant.modal.title',
            })}
            description={
              <Stack>
                <FormattedMessage
                  id="cohort.creation.email.participants.remove.all.participant.modal.description"
                  values={{
                    action: deleteAllAction,
                  }}
                />
                <Input
                  onChange={({ target }) => setDeleteAllInputValue(target.value)}
                  borderRadius={3}
                  mb={3}
                  placeholder={formatMessage({
                    id: 'cohort.creation.email.participants.remove.all.participant.modal.input.placeholder',
                  })}
                />
              </Stack>
            }
            ctaLabel={formatMessage({
              id: 'common.delete',
            })}
            size="xl"
            isButtonDisabled={deleteAllInputValue.trim() !== deleteAllAction}
            isButtonLoading={isDeleteAllButtonLoading}
          >
            {({ openModal }) => (
              <Button
                leftIcon={<FiTrash2 />}
                onClick={openModal}
                isDisabled={selectedParticipants.length === 0}
              >
                <FormattedMessage id="participant.cohorts.delete.from.organization" />
              </Button>
            )}
          </InterceptAndConfirm>
        </HStack>
      </ActionButtonContainer>
    </>
  )
}
