import { Box, HStack, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import moment from 'moment'
import { useMemo } from 'react'

import { AdvancedTable } from '../../../components/advanced-table'
import { type AdvancedTableColumns } from '../../../components/advanced-table/types'
import { sortNumbers, sortStrings } from '../../../utils/sort-functions'
import { useDashboardContext } from '../../shared/dashboard-context'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

type competencyTimeData = {
  cohortExtId: string
  cohortName: string
  time: number
  index: number
}
const columns: AdvancedTableColumns<competencyTimeData> = [
  {
    key: 'cohortName',
    title: (
      <FormattedMessage id="dashboard.cards.popularScenarios.details.tab.scenarioName" />
    ),
    field: 'cohortName',
    sorter: (a, b) => sortStrings(a.cohortName, b.cohortName),
    customRender: record => (
      <HStack gap="12px">
        <Box minW="20px" w="20px" h="20px" borderRadius="50%" bg={colors.blue[500]}>
          <Text textAlign="center" fontSize="12px" color="white">
            {record.index}
          </Text>
        </Box>
        <Text fontSize="14px">{record.cohortName}</Text>
      </HStack>
    ),
  },
  {
    key: 'time',
    title: <FormattedMessage id="dashboard.cards.popularScenarios.tab.column.time" />,
    field: 'time',
    sorter: (a, b) => sortNumbers(a.time, b.time),
    align: 'end',
    customRender: record => moment.duration(record.time).humanize(),
  },
]

export const CompetencyTimeCardDetails = () => {
  const { pageState } = useDashboardContext()
  const { dataSlot } = useDashboardCardData('competencyTime')

  const timeToCompetencyData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    return data.timeToCompetencyOverall.map(({ cohortExtId, duration }, index) => ({
      cohortExtId,
      cohortName: data.cohortNames[data.cohortExtIds.indexOf(cohortExtId)]!,
      time: duration,
      index: index + 1,
    }))
  }, [dataSlot])

  return (
    <DetailPageWrapper cardId="competencyTime">
      <AdvancedTable
        rows={timeToCompetencyData}
        keyExtractor={record => record.cohortExtId}
        columns={columns}
        isLoading={pageState === 'loading'}
      />
    </DetailPageWrapper>
  )
}
