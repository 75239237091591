import { Td, Tr } from '@chakra-ui/react'
import { colors } from '@repo/ui'
import { memo } from 'react'

import { renderCellContent } from './table-row/render-cell-content'
import { type AdvancedTableProps } from './types'

type TableRowProps<T> = {
  record: T
} & Pick<AdvancedTableProps<T>, 'onRowClick' | 'columns'>

const TableRow = <T,>({ record, onRowClick, columns }: TableRowProps<T>) => (
  <Tr
    onClick={() => onRowClick?.(record)}
    transition="all 0.2s"
    _hover={
      onRowClick && {
        background: colors.gray[100],
        cursor: 'pointer',
      }
    }
  >
    {columns.map(column => (
      <Td
        key={column.key}
        style={{
          fontSize: 14,
          textAlign: column.align,
          borderColor: colors.gray[200],
        }}
        whiteSpace="normal"
      >
        {renderCellContent(column, record)}
      </Td>
    ))}
  </Tr>
)

const MemoizedTableRow = memo(TableRow) as typeof TableRow

export { MemoizedTableRow as TableRow }
