import { FormControl, FormErrorMessage, FormHelperText } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { type ReactNode, useMemo } from 'react'
import { type FieldError } from 'react-hook-form'

import { colors } from '../../../theme/main'
import { FormTooltipProvider } from './form-tooltip-provider'
import { LabelWrapper } from './label-wrapper'
import { type FieldBaseProps } from './types'

type FormControlWrapperProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldBaseProps: FieldBaseProps<any, any, any>
  children: ReactNode
  error: FieldError | undefined
}

export const FormControlWrapper = ({
  children,
  fieldBaseProps: { helperTextKey, rules, name, label, labelKey, hideLabel, tooltipKey },
  error,
}: FormControlWrapperProps) => {
  const textBelowField = useMemo(() => {
    if (error?.message) {
      return <FormErrorMessage fontSize={12}>{error?.message}</FormErrorMessage>
    }

    if (helperTextKey) {
      return (
        <FormHelperText fontSize={12} color={colors.gray[500]}>
          <FormattedMessage id={helperTextKey} />
        </FormHelperText>
      )
    }

    return null
  }, [helperTextKey, error?.message])

  return (
    <FormControl isRequired={!!rules?.required} isInvalid={!!error}>
      <LabelWrapper name={name} label={label} labelKey={labelKey} hideLabel={hideLabel} />

      <FormTooltipProvider tooltipKey={tooltipKey}>{children}</FormTooltipProvider>

      {textBelowField}
    </FormControl>
  )
}
