import { Flex, Th } from '@chakra-ui/react'
import { colors } from '@repo/ui'

import { SortIndicator } from './column-header-cell/sort-indicator'
import { type AdvancedTableColumn, type ColumnSortDir } from './types'

interface ColumnHeaderCellProps<T> {
  column: AdvancedTableColumn<T>
  onClick: () => void
  currentSortDir?: ColumnSortDir
}

export const ColumnHeaderCell = <T,>({
  column,
  onClick,
  currentSortDir,
}: ColumnHeaderCellProps<T>) => (
  <Th
    fontSize={14}
    fontWeight={500}
    color={colors.gray[700]}
    borderColor={colors.gray[200]}
    cursor={column.sorter && 'pointer'}
    width={column.width}
    minWidth={column.minWidth}
    onClick={onClick}
  >
    <Flex
      justify={column.align}
      textAlign={column.align}
      gap={1}
      align="center"
      whiteSpace="normal"
    >
      {column.title}
      {!!column.sorter && <SortIndicator sort={currentSortDir} />}
    </Flex>
  </Th>
)
