import {
  type AdvancedTableColumn,
  type AdvancedTableColumns,
  type ColumnSort,
  type ColumnSortDir,
  type ColumnWithField,
  type SorterFunction,
} from './types'

export const columnDoesNotHaveCustomRender = <T>(
  column: AdvancedTableColumn<T>
): column is ColumnWithField<T> => !('customRender' in column)

export const getNextSortDir = (currentDir?: ColumnSortDir) => {
  if (!currentDir) {
    return 'asc'
  }

  if (currentDir === 'asc') {
    return 'desc'
  }

  // clear sorting if clicked for the 3rd time
  return undefined
}

export const sortRows = <T>(
  rows: Array<T>,
  sort: ColumnSort,
  sorter: SorterFunction<T>
) =>
  rows.toSorted((row1, row2) => {
    let comparison = sorter(row1, row2)

    if (sort.dir === 'desc') {
      // reverse if descending
      comparison = -comparison
    }

    return comparison
  })

const getInnerText = reactNode => {
  let innerText = ''

  // If the element is a text node, return its text content
  if (typeof reactNode === 'string') {
    innerText += reactNode
  }

  // If the element is an array of children, recursively call getInnerText on each child
  if (Array.isArray(reactNode)) {
    reactNode.forEach(child => {
      innerText += getInnerText(child)
    })
  }

  // If the element has props and children, recursively call getInnerText on each child
  if (reactNode?.props?.children) {
    innerText += getInnerText(reactNode.props.children)
  }

  return innerText
}

export const extractSearchableStringsFromRow = <T>(
  row: T,
  columns: AdvancedTableColumns<T>
) =>
  columns
    .map(column => {
      if (columnDoesNotHaveCustomRender(column)) {
        return String(row[column.field])
      }

      const jsx = column.customRender(row)

      return getInnerText(jsx)
    })
    .filter(Boolean)
