import { Box, FormControl } from '@chakra-ui/react'
import { useIntl } from '@repo/i18n'
import { LabelWrapper, RHF } from '@repo/ui'
import {
  type Control,
  type FieldValues,
  type Path,
  type UseControllerProps,
  type UseFormSetValue,
  type UseFormWatch,
} from 'react-hook-form'

import { ToneSelectionButtons } from './textarea-with-tones/tone-selection-buttons'
import { type Tone } from './textarea-with-tones/types'

interface TextAreaWithTonesProps<T extends FieldValues> {
  control: Control<T>
  name: Path<T>
  rules?: UseControllerProps<T>['rules']
  watch: UseFormWatch<T>
  setValue: UseFormSetValue<T>
  tones: Array<Tone>
  labelKey?: I18nKey
  isDisabled?: boolean
  placeholderKey?: I18nKey
  tooltipKey?: I18nKey
}

export const TextAreaWithTones = <T extends FieldValues>({
  control,
  name,
  rules,
  watch,
  setValue,
  tones,
  labelKey,
  isDisabled,
  placeholderKey,
  tooltipKey,
}: TextAreaWithTonesProps<T>) => {
  const { formatMessage } = useIntl()

  return (
    <Box>
      <FormControl isRequired={!!rules?.required}>
        <LabelWrapper name={name} labelKey={labelKey} />
      </FormControl>

      {tones.length > 0 && (
        <ToneSelectionButtons
          currentInputValue={watch(name)}
          setInputValue={value => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            setValue(name, value as any, {
              shouldValidate: !!value,
              shouldDirty: true,
            })
          }}
          tones={tones}
        />
      )}

      <RHF.TextArea
        control={control}
        name={name}
        rules={rules}
        helperTextKey="common.youCanEdit"
        hideLabel
        componentProps={{
          isDisabled,
          placeholder: placeholderKey ? formatMessage({ id: placeholderKey }) : undefined,
        }}
        tooltipKey={tooltipKey}
      />
    </Box>
  )
}
