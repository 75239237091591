import { Box, Text } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors, PersonaThumbnail, showToast, useConfirmationModal } from '@repo/ui'
import { assertExists } from '@repo/utils'
import { useNavigate, useParams } from 'react-router-dom'

import { CardGrid } from '../../components/card-grid'
import { CenteredSpinner } from '../../components/centered-spinner'
import { useGoogleStorageAsset } from '../../hooks/use-google-storage-asset'
import { type Scenario, type ScenarioType } from '../../types/api-types'
import { client } from '../../utils/openapi-client'
import { useOpenapiSWR } from '../../utils/use-openapi-swr'

export const scenarioBuilderPaths: Record<ScenarioType, string> = {
  aiSupported: 'scripted',
  manual: 'manual',
  realtimeBuilder: 'realtime',
}

export const ScenarioCard = ({
  scenario,
  sortable,
}: {
  scenario: Scenario
  sortable?: boolean
}) => {
  const { programExtId, trainingExtId } = useParams()

  assertExists(programExtId)
  assertExists(trainingExtId)

  const { data: program } = useOpenapiSWR('getProgramByExtId', {
    params: { extId: programExtId },
  })

  const isDraft = program?.status === 'unpublished'

  const scenariosSWR = useOpenapiSWR('getScenarios', {
    params: { programExtId, trainingExtId },
  })

  const navigate = useNavigate()

  const [deleteModal, showDeleteModal] = useConfirmationModal({
    onConfirm: async () => {
      const res = await client.delete('deleteScenarioByExtId', {
        params: { path: { programExtId, trainingExtId, extId: scenario.extId } },
      })

      if (res.error) {
        return showToast({ messageKey: 'common.error.unexpected', status: 'error' })
      }

      scenariosSWR.mutate(
        scenarios => scenarios?.filter(s => s.extId !== scenario.extId),
        false
      )
    },
    isDestructive: true,
    descriptionKey: 'scenario.button.delete.confirm',
  })

  const { persona, type, description, thumbnailImgUrl } = scenario

  if (!program) {
    return <CenteredSpinner />
  }

  return (
    <CardGrid.Card
      imageComponent={
        (type === 'realtimeBuilder' || type === 'aiSupported') && persona ? (
          <Box
            w="full"
            pointerEvents="none"
            bg="white"
            aspectRatio="16/9"
            overflow="hidden"
          >
            <PersonaThumbnail
              avatarPreviewUrl={persona.videoGenerationAvatar?.imageUrl}
              bgUrl={type === 'realtimeBuilder' ? null : persona.backgroundUrl}
              bgColor={type === 'realtimeBuilder' ? '#CBD5E0' : null}
              useGoogleStorageAsset={useGoogleStorageAsset}
            />
          </Box>
        ) : undefined
      }
      image={thumbnailImgUrl ?? ''}
      onClick={() => {
        if (!sortable) {
          const builderPath = scenarioBuilderPaths[scenario.type]
          const scenarioUrl = `scenarios/${scenario.extId}/${builderPath}`

          navigate(scenarioUrl)
        }
      }}
      footer={
        sortable
          ? {
              type: 'sortable',
              sortableId: scenario.extId,
            }
          : undefined
      }
      title={scenario.title ?? <FormattedMessage id="scenario.draftTitle" />}
      menuItems={
        sortable
          ? undefined
          : [
              {
                onClick: showDeleteModal,
                isDestructive: true,
                labelKey: 'common.delete',
                isDisabled: program.status !== 'unpublished',
              },
            ]
      }
      tag={
        isDraft
          ? {
              text: <FormattedMessage id="common.draft" />,
              sx: {
                bgColor: colors.yellow[500],
                color: colors.gray[800],
                pos: 'absolute',
                top: 4,
                right: 0,
              },
              cut: 'right',
            }
          : undefined
      }
    >
      {deleteModal}
      <Text minH="100px" noOfLines={3}>
        {description}
      </Text>
    </CardGrid.Card>
  )
}
