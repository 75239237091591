import { Button, Center, Stack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { SectionHeader } from '@repo/ui'
import { Link } from 'react-router-dom'

import { CardGrid } from '../../../components/card-grid'
import { useOpenapiSWR } from '../../../utils/use-openapi-swr'
import { ProgramTemplateCard } from '../../program-template-library/create-program-from-template/templates-grid/program-template-card'
import { SkeletonProgramTemplateCards } from '../../program-template-library/create-program-from-template/templates-grid/skeleton-program-template-cards'

export const SuggestedTemplatesSection = () => {
  const { data, isLoading } = useOpenapiSWR('getAllProgramTemplates')

  const templates = data?.slice(0, 4) ?? []

  if (!isLoading && !templates.length) {
    return null
  }

  return (
    <Stack gap={8}>
      <SectionHeader
        titleKey="coaching.programList.suggestions.title"
        subtitleKey="coaching.programList.suggestions.subtitle"
      />

      <CardGrid>
        {isLoading ? (
          <SkeletonProgramTemplateCards count={4} />
        ) : (
          templates.map(template => (
            <ProgramTemplateCard key={template.extId} template={template} />
          ))
        )}
      </CardGrid>

      {(data?.length ?? 0) > 4 && (
        <Center>
          <Button variant="outline" as={Link} to="/best_practices">
            <FormattedMessage id="coaching.programList.suggestions.moreButton" />
          </Button>
        </Center>
      )}
    </Stack>
  )
}
