import { TableContainer, type TableContainerProps } from '@chakra-ui/react'
import { colors } from '@repo/ui'

export const StyledTableContainer = (props: TableContainerProps) => (
  <TableContainer
    sx={{
      mt: 2,
      overflowY: 'auto',
      scrollbarColor: `${colors.gray[200]} transparent`,
      // Safari
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.gray[200],
        borderRadius: 'full',
      },
    }}
    {...props}
  />
)
