import { Box, Button, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { colors } from '@repo/ui'
import { take, takeRight } from 'lodash-es'
import { useMemo, useState } from 'react'
import { MdQueryStats } from 'react-icons/md'

import { type CoachingResultDimensionExcludedUnused } from '../../../coaching/coaching-result'
import { AdvancedTable } from '../../../components/advanced-table'
import { type AdvancedTableColumns } from '../../../components/advanced-table/types'
import { CenteredSpinner } from '../../../components/centered-spinner'
import { sortNumbers, sortStrings } from '../../../utils/sort-functions'
import { MetricsComparisonModal } from './communication-and-language-card-details/metrics-comparison-modal'
import { PercentageBadge } from './communication-and-language-card-details/percentage-badge'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { ScoreCircle } from './shared/score-circle'
import { ToggleBetweenTwo } from './shared/toggle-between-two'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

const getColumns = (
  onTrendClick: (cohortName: string, cohortIndex: number) => void
): AdvancedTableColumns<{
  id: string
  cohortIndex: number
  cohortName: string
  sentenceLength: number | null
  socialFocus: number | null
  adjectives: number | null
  understandability: number | null
  speakingPausesMinute: number | null
  positiveLanguage: number | null
  speakingSpeed: number | null
  facialExpressions: number | null
  trendChart: null
}> => [
  {
    key: 'cohortName',
    title: (
      <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.cohortName.table.header" />
    ),
    field: 'cohortName',
    sorter: (a, b) => sortStrings(a.cohortName, b.cohortName),
  },
  {
    key: 'sentenceLength',
    title: <FormattedMessage id="coaching.result.sentenceLength.label" />,
    field: 'sentenceLength',
    sorter: (a, b) => sortNumbers(a.sentenceLength, b.sentenceLength),
    customRender: ({ sentenceLength }) => <PercentageBadge value={sentenceLength} />,
  },
  {
    key: 'socialFocus',
    title: <FormattedMessage id="coaching.result.socialFocus.label" />,
    field: 'socialFocus',
    sorter: (a, b) => sortNumbers(a.socialFocus, b.socialFocus),
    customRender: ({ socialFocus }) => <PercentageBadge value={socialFocus} />,
  },
  {
    key: 'adjectives',
    title: <FormattedMessage id="coaching.result.adjectives.label" />,
    field: 'adjectives',
    sorter: (a, b) => sortNumbers(a.adjectives, b.adjectives),
    customRender: ({ adjectives }) => <PercentageBadge value={adjectives} />,
  },
  {
    key: 'understandability',
    title: <FormattedMessage id="coaching.result.understandability.label" />,
    field: 'understandability',
    sorter: (a, b) => sortNumbers(a.understandability, b.understandability),
    customRender: ({ understandability }) => (
      <PercentageBadge value={understandability} />
    ),
  },
  {
    key: 'speakingPausesMinute',
    title: <FormattedMessage id="coaching.result.speakingPausesMinute.label" />,
    field: 'speakingPausesMinute',
    sorter: (a, b) => sortNumbers(a.speakingPausesMinute, b.speakingPausesMinute),
    customRender: ({ speakingPausesMinute }) => (
      <PercentageBadge value={speakingPausesMinute} />
    ),
  },
  {
    key: 'positiveLanguage',
    title: <FormattedMessage id="coaching.result.positiveLanguage.label" />,
    field: 'positiveLanguage',
    sorter: (a, b) => sortNumbers(a.positiveLanguage, b.positiveLanguage),
    customRender: ({ positiveLanguage }) => <PercentageBadge value={positiveLanguage} />,
  },
  {
    key: 'speakingSpeed',
    title: <FormattedMessage id="coaching.result.speakingSpeed.label" />,
    field: 'speakingSpeed',
    sorter: (a, b) => sortNumbers(a.speakingSpeed, b.speakingSpeed),
    customRender: ({ speakingSpeed }) => <PercentageBadge value={speakingSpeed} />,
  },
  {
    key: 'facialExpressions',
    title: <FormattedMessage id="coaching.result.facialExpressions.label" />,
    field: 'facialExpressions',
    sorter: (a, b) => sortNumbers(a.facialExpressions, b.facialExpressions),
    customRender: ({ facialExpressions }) => (
      <PercentageBadge value={facialExpressions} />
    ),
  },
  {
    key: 'trendChart',
    title: '',
    field: 'trendChart',
    customRender: ({ cohortName, cohortIndex }) => (
      <Button
        leftIcon={<MdQueryStats />}
        onClick={() => onTrendClick(cohortName, cohortIndex)}
      >
        <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.trendOverTime.table.button" />
      </Button>
    ),
  },
]

const initialRowValues = {
  id: 0,
  cohortName: '',
  sentenceLength: null,
  socialFocus: null,
  adjectives: null,
  understandability: null,
  speakingPausesMinute: null,
  positiveLanguage: null,
  speakingSpeed: null,
  facialExpressions: null,
  trendChart: null,
}

export const CommunicationAndLanguageCardDetails = () => {
  const { dataSlot } = useDashboardCardData('communicationAndLanguage')
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [showStrengths, setShowStrengths] = useState(true)
  const [selectedCohortName, setSelectedCohortName] = useState('')
  const [selectedCohortIndex, setSelectedCohortIndex] = useState(0)

  const onTrendClick = (cohortName: string, cohortIndex) => {
    setSelectedCohortName(cohortName)
    setSelectedCohortIndex(cohortIndex)
    onOpen()
  }

  const { tableRows, strengthKeys, weakKeys } = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return { tableRows: [], strengthKeys: [], weakKeys: [] }
    }

    const { data } = dataSlot

    const rows = data.cohortNames.map((cohortName, index) => {
      const rowData = {
        ...initialRowValues,
        id: data.cohortExtIds[index]!,
        cohortName,
        cohortIndex: index,
      }

      data.metricScores.forEach(({ metric, cohorts }) => {
        rowData[metric] = cohorts[index]
      })

      return rowData
    })

    const keys = data.metricScores.map(({ metric }) => metric)

    return { tableRows: rows, strengthKeys: take(keys, 4), weakKeys: takeRight(keys, 4) }
  }, [dataSlot])

  const { filteredColumns, scoringVariables } = useMemo(() => {
    const metrics = showStrengths ? strengthKeys : weakKeys
    const columnKeys: Array<string> = ['cohortName', ...metrics, 'trendChart']
    const columnsData = getColumns(onTrendClick).filter(
      ({ key }) => columnKeys.indexOf(key) > -1
    )

    columnsData.sort((a, b) => columnKeys.indexOf(a.key) - columnKeys.indexOf(b.key))

    return { filteredColumns: columnsData, scoringVariables: metrics }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showStrengths, strengthKeys, weakKeys])

  if (dataSlot.status !== 'loaded') {
    return <CenteredSpinner />
  }

  const { data } = dataSlot

  return (
    <DetailPageWrapper
      cardId="communicationAndLanguage"
      titleStartContent={
        data.communicationScoreOverall !== null && (
          <ScoreCircle value={data.communicationScoreOverall} />
        )
      }
    >
      <AdvancedTable
        rows={tableRows}
        keyExtractor={record => record.id}
        columns={filteredColumns}
        tableStartContent={
          <VStack alignItems="flex-start">
            <Box mb={5}>
              <Text
                fontSize="12px"
                fontWeight={500}
                mt={5}
                mb={1}
                color={colors.gray[600]}
              >
                <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.scoring.variables.toggle" />
              </Text>
              <ToggleBetweenTwo
                isLeftActive={showStrengths}
                toggleValue={() => setShowStrengths(!showStrengths)}
                left={
                  <Text mx={3}>
                    <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.strengths.toggle.option" />
                  </Text>
                }
                right={
                  <Text mx={3}>
                    <FormattedMessage id="dashboard.cards.communicationAndLanguage.details.weaknesses.toggle.option" />
                  </Text>
                }
              />
            </Box>
          </VStack>
        }
      />
      <MetricsComparisonModal
        isOpen={isOpen}
        onClose={onClose}
        cohortName={selectedCohortName}
        cohortIndex={selectedCohortIndex}
        scoringVariables={
          // excluded version is a subset, so this is safe
          scoringVariables as Array<CoachingResultDimensionExcludedUnused>
        }
      />
    </DetailPageWrapper>
  )
}
