import type { SystemStyleObject } from '@chakra-ui/react'

export const scrollbarStyles: SystemStyleObject = {
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderRadius: '10px',
  },

  scrollbarWidth: 'thin', // For Firefox
  scrollbarColor: 'rgba(255, 255, 255, 0.5) transparent',
}
