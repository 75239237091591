import { FormFieldArray } from './rhf/form-field-array'
import { FormFieldAutocompleteSelect } from './rhf/form-field-autocomplete-select'
import { FormFieldInput } from './rhf/form-field-input'
import { FormFieldMarkdown } from './rhf/form-field-markdown'
import { FormFieldNumberInput } from './rhf/form-field-number-input'
import { FormFieldRadioButtonGroup } from './rhf/form-field-radio-button-group'
import { FormFieldSelect } from './rhf/form-field-select'
import { FormFieldSlider } from './rhf/form-field-slider'
import { FormFieldTextarea } from './rhf/form-field-textarea'

export const RHF = {
  Slider: FormFieldSlider,
  RadioButtonGroup: FormFieldRadioButtonGroup,
  Select: FormFieldSelect,
  Input: FormFieldInput,
  AutocompleteSelect: FormFieldAutocompleteSelect,
  NumberInput: FormFieldNumberInput,
  TextArea: FormFieldTextarea,
  FieldArray: FormFieldArray,
  Markdown: FormFieldMarkdown,
}
