import { Box, HStack, Stack, Table, Tbody, Tr } from '@chakra-ui/react'
import { EmptyStateIndicator } from '@repo/ui'
import { memo, useMemo, useState } from 'react'

import { ColumnHeaderCell } from './advanced-table/column-header-cell'
import { StyledTableContainer } from './advanced-table/styled-table-container'
import { StyledThead } from './advanced-table/styled-thead'
import { TableLoadingRows } from './advanced-table/table-loading-rows'
import { TableRow } from './advanced-table/table-row'
import { type AdvancedTableProps, type ColumnSort } from './advanced-table/types'
import {
  extractSearchableStringsFromRow,
  getNextSortDir,
  sortRows,
} from './advanced-table/utils'
import { SearchInput } from './search-input'

const AdvancedTable = <T,>({
  rows,
  columns,
  keyExtractor,
  tableStartContent,
  onRowClick,
  searchPlaceholderKey,
  sx,
  isLoading,
  emptyStateLabelKey,
  withSearchInput = true,
}: AdvancedTableProps<T>) => {
  const [filter, setFilter] = useState('')
  const [columnSort, setColumnSort] = useState<ColumnSort>()

  const handleHeaderClick = (columnKey: string) => {
    const column = columns.find(c => c.key === columnKey)

    if (!column?.sorter) {
      return
    }

    const isClickedColumnSorted = columnKey === columnSort?.key
    const nextSortDir = getNextSortDir(isClickedColumnSorted ? columnSort.dir : undefined)

    if (!nextSortDir) {
      return setColumnSort(undefined)
    }

    setColumnSort({
      key: columnKey,
      dir: nextSortDir,
    })
  }

  const sortedRows = useMemo(() => {
    if (!columnSort) {
      return rows
    }

    const sorter = columns.find(column => column.key === columnSort.key)?.sorter

    if (!sorter) {
      return rows
    }

    return sortRows(rows, columnSort, sorter)
  }, [rows, columnSort, columns])

  const hasFilter = !!filter.length
  const searchableStringsOfRows = useMemo(() => {
    // this is an optimization to avoid calculating the searchable strings if there is no filter, which is the initial state
    if (!hasFilter) {
      return []
    }

    return sortedRows.map(record => extractSearchableStringsFromRow(record, columns))
  }, [sortedRows, columns, hasFilter])

  const filteredRows = useMemo(() => {
    const trimmedFilter = filter.trim()

    if (trimmedFilter.length === 0) {
      return sortedRows
    }

    return sortedRows.filter((_, index) =>
      searchableStringsOfRows[index]?.some(value =>
        String(value).toLowerCase().includes(trimmedFilter.toLowerCase())
      )
    )
  }, [sortedRows, filter, searchableStringsOfRows])

  const [isScrolled, setIsScrolled] = useState(false)

  return (
    <Stack gap={0} sx={sx} overflow="hidden" minHeight={250}>
      <HStack pt={1} pr={1}>
        <Box flex={1}>{tableStartContent}</Box>
        {withSearchInput && (
          <SearchInput
            placeholderKey={searchPlaceholderKey}
            value={filter}
            onChange={setFilter}
          />
        )}
      </HStack>

      <StyledTableContainer
        onScroll={e => {
          setIsScrolled(e.currentTarget.scrollTop > 0)
        }}
      >
        <Table>
          <StyledThead shadowVisible={isScrolled}>
            <Tr>
              {columns.map(column => (
                <ColumnHeaderCell
                  key={column.key}
                  column={column}
                  onClick={() => handleHeaderClick(column.key)}
                  currentSortDir={
                    columnSort?.key === column.key ? columnSort.dir : undefined
                  }
                />
              ))}
            </Tr>
          </StyledThead>
          {!isLoading && (
            <Tbody>
              {filteredRows.map(record => (
                <TableRow
                  key={keyExtractor(record)}
                  columns={columns}
                  record={record}
                  onRowClick={onRowClick}
                />
              ))}
            </Tbody>
          )}
        </Table>
      </StyledTableContainer>
      {isLoading ? (
        <TableLoadingRows />
      ) : (
        filteredRows.length === 0 && (
          <EmptyStateIndicator
            messageKey={
              filter.length
                ? 'general.noSearchResult'
                : (emptyStateLabelKey ?? 'general.noDataAvailable')
            }
          />
        )
      )}
    </Stack>
  )
}

const MemoizedAdvancedTable = memo(AdvancedTable) as typeof AdvancedTable

export { MemoizedAdvancedTable as AdvancedTable }
