import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FormattedMessage, useIntl } from '@repo/i18n'
import { showToast } from '@repo/ui'
import { useMemo } from 'react'
import { RiAddFill, RiErrorWarningLine, RiMoreFill } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

import { ContentLoader } from '../../builder/components/content-loader'
import { ActionButtonContainer } from '../../coaching-benchmark-profiles/components/action-button-container'
import { AdvancedTable } from '../../components/advanced-table'
import { type AdvancedTableColumns } from '../../components/advanced-table/types'
import { InterceptAndConfirm } from '../../components/intercept-and-confirm'
import { formatDateTime } from '../../utils/dates'
import { sortDates, sortStrings } from '../../utils/sort-functions'
import { type CohortsListItem } from '../types/cohorts-list-item'
import { useCohorts } from '../use-cohorts'
import { CohortCreationModal } from './cohort-creation-modal/cohort-creation-modal'

export const CohortsTable = ({ isBenchmarking }: { isBenchmarking: boolean }) => {
  const { formatMessage } = useIntl()
  const { useCohortsList, deleteCohort: removeCohort } = useCohorts()
  const { data, status, refresh: refreshList } = useCohortsList({ isBenchmarking })
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const deleteCohort = async (cohortId: string) => {
    await removeCohort(cohortId)
    refreshList()
  }

  const rowLabels = useMemo(
    () => ({
      ariaLabel: formatMessage({
        id: 'cohort.details.tokens.list.options.ariaLabel',
      }),
      modalDescription: formatMessage({
        id: 'cohort.delete.confirmModal.description',
      }),
      modalLabel: formatMessage({
        id: 'common.delete',
      }),
    }),
    [formatMessage]
  )

  const columns: AdvancedTableColumns<CohortsListItem> = [
    {
      key: 'name',
      title: <FormattedMessage id="common.name" />,
      field: 'name',
      sorter: (a, b) => sortStrings(a.name, b.name),
    },
    {
      key: 'mode',
      title: <FormattedMessage id="cohorts.list.table.invitation.mode" />,
      customRender: record =>
        record.type === 'token' ? (
          <FormattedMessage id="cohorts.list.table.row.token.mode" />
        ) : (
          <FormattedMessage id="cohorts.list.table.row.email.mode" />
        ),
    },
    {
      key: 'participants',
      title: <FormattedMessage id="cohorts.list.table.participants" />,

      customRender: record =>
        record.type === 'token' ? record.activeTokens : record.activeEmails,
    },
    {
      key: 'createdAt',
      title: <FormattedMessage id="cohorts.list.table.createdAt" />,
      field: 'createdAt',
      sorter: (a, b) => sortDates(a.createdAt, b.createdAt),
      customRender: record => formatDateTime(record.createdAt),
    },
    {
      key: 'actions',
      title: '',
      customRender: record => (
        <Menu>
          <MenuButton
            as={IconButton}
            variant="ghost"
            icon={<RiMoreFill />}
            aria-label={rowLabels.ariaLabel}
            onClick={e => e.stopPropagation()}
          />
          <MenuList onClick={e => e.stopPropagation()}>
            <InterceptAndConfirm
              onConfirm={async () => {
                try {
                  await deleteCohort(record.extId)
                  showToast({
                    messageKey: 'common.alert.deleted',
                    status: 'success',
                  })
                } catch (err) {
                  showToast({
                    messageKey: 'general.error.deleting.data',
                    status: 'error',
                  })
                }
              }}
              title={formatMessage(
                { id: 'cohort.delete.confirmModal.title' },
                { cohortName: record.name }
              )}
              description={rowLabels.modalDescription}
              ctaLabel={formatMessage({ id: 'common.delete' })}
            >
              {({ openModal }) => (
                <MenuItem color="indicator.red-dark" onClick={openModal}>
                  <Text>{rowLabels.modalLabel}</Text>
                </MenuItem>
              )}
            </InterceptAndConfirm>
          </MenuList>
        </Menu>
      ),
    },
  ]

  return (
    <>
      <ContentLoader status={status}>
        {status === 'loaded' && data?.length ? (
          <AdvancedTable
            rows={data}
            keyExtractor={record => record.extId}
            columns={columns}
            onRowClick={record =>
              isBenchmarking
                ? navigate(`/coaching/benchmarking/cohorts/${record.extId}`)
                : navigate(`/participants/cohorts/${record.extId}`)
            }
            sx={{ minHeight: '90%' }}
          />
        ) : (
          <Box display="flex" alignItems="center" maxW="800px" pt={8}>
            <Icon mr={4} as={RiErrorWarningLine} />
            <Text>
              <FormattedMessage id="cohorts.list.noCohorts" />
            </Text>
          </Box>
        )}
      </ContentLoader>
      <ActionButtonContainer>
        <Button
          leftIcon={<RiAddFill size="1.25em" />}
          iconSpacing="1"
          colorScheme="blue"
          onClick={onOpen}
        >
          <FormattedMessage id="cohorts.list.button.create" />
        </Button>
      </ActionButtonContainer>

      <CohortCreationModal
        isOpen={isOpen}
        onClose={onClose}
        isBenchmarking={isBenchmarking}
      />
    </>
  )
}
