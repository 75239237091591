import { Box } from '@chakra-ui/react'
import { FormattedMessage } from '@repo/i18n'
import { useMemo, useState } from 'react'
import { FiPercent } from 'react-icons/fi'

import { AdvancedTable } from '../../../components/advanced-table'
import { type AdvancedTableColumns } from '../../../components/advanced-table/types'
import { sortNumbers, sortStrings } from '../../../utils/sort-functions'
import { useDashboardContext } from '../../shared/dashboard-context'
import { DetailPageWrapper } from './shared/detail-page-wrapper'
import { ToggleBetweenTwo } from './shared/toggle-between-two'
import { useDashboardCardData } from './shared/use-dashboard-card-data'

export const LearningDevelopmentFunnelCardDetails = () => {
  const { dataSlot } = useDashboardCardData('learningDevelopmentFunnel')
  const { pageState } = useDashboardContext()
  const [isPercentageActive, setIsPercentageActive] = useState(false)

  const renderNumberOrPercentage = (value: string | number) =>
    isPercentageActive ? `${value}%` : value

  const columns: AdvancedTableColumns<{
    id: string
    cohortName: string
    completedAll: number
    finishedAtLeastOne: number
    registeredNoRecordings: number
    notRegistered: number
  }> = [
    {
      key: 'cohortName',
      title: (
        <FormattedMessage id="dashboard.cards.traineeCount.details.tab.cohortName" />
      ),
      field: 'cohortName',
      sorter: (a, b) => sortStrings(a.cohortName, b.cohortName),
    },
    {
      key: 'completedAll',
      title: (
        <FormattedMessage id="dashboard.cards.learningDevelopmentFunnel.data.completedAll" />
      ),
      field: 'completedAll',
      sorter: (a, b) => sortNumbers(a.completedAll, b.completedAll),
      align: 'end',
      customRender: record => renderNumberOrPercentage(record.completedAll),
    },
    {
      key: 'finishedAtLeastOne',
      title: (
        <FormattedMessage id="dashboard.cards.learningDevelopmentFunnel.data.finishedAtLeastOne" />
      ),
      field: 'finishedAtLeastOne',
      sorter: (a, b) => sortNumbers(a.finishedAtLeastOne, b.finishedAtLeastOne),
      align: 'end',
      customRender: record => renderNumberOrPercentage(record.finishedAtLeastOne),
    },
    {
      key: 'registeredNoRecordings',
      title: (
        <FormattedMessage id="dashboard.cards.learningDevelopmentFunnel.data.registeredNoRecordings" />
      ),
      field: 'registeredNoRecordings',
      sorter: (a, b) => sortNumbers(a.registeredNoRecordings, b.registeredNoRecordings),
      align: 'end',
      customRender: record => renderNumberOrPercentage(record.registeredNoRecordings),
    },
    {
      key: 'notRegistered',
      title: (
        <FormattedMessage id="dashboard.cards.learningDevelopmentFunnel.data.notRegistered" />
      ),
      field: 'notRegistered',
      sorter: (a, b) => sortNumbers(a.notRegistered, b.notRegistered),
      align: 'end',
      customRender: record => renderNumberOrPercentage(record.notRegistered),
    },
  ]

  const tableData = useMemo(() => {
    if (dataSlot.status !== 'loaded') {
      return []
    }

    const { data } = dataSlot

    const dataType = isPercentageActive
      ? data.usersProgressPercentagePerTimestamp
      : data.usersProgressValuePerTimestamp

    return data.cohortNames.map((cohortName, index) => ({
      id: data.cohortExtIds[index]!,
      cohortName,
      completedAll: dataType.completed[index]!.at(-1) ?? 0,
      registeredNoRecordings: dataType.withoutProgress[index]!.at(-1) ?? 0,
      finishedAtLeastOne: dataType.inProgress[index]!.at(-1) ?? 0,
      notRegistered: dataType.open[index]!.at(-1) ?? 0,
    }))
  }, [dataSlot, isPercentageActive])

  return (
    <DetailPageWrapper cardId="learningDevelopmentFunnel">
      <AdvancedTable
        isLoading={pageState === 'loading'}
        rows={tableData}
        keyExtractor={record => record.id}
        columns={columns}
        tableStartContent={
          <Box w="fit-content">
            <ToggleBetweenTwo
              isLeftActive={!isPercentageActive}
              toggleValue={() => setIsPercentageActive(!isPercentageActive)}
              left="123"
              right={<FiPercent />}
            />
          </Box>
        }
      />
    </DetailPageWrapper>
  )
}
