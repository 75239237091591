import {
  Button,
  type ButtonProps,
  FormControl,
  FormHelperText,
  HStack,
  IconButton,
  Stack,
  type SystemStyleObject,
} from '@chakra-ui/react'
import { FormattedMessage, type ReactNodeOrI18nKey } from '@repo/i18n'
import { type ReactNode } from 'react'
import {
  type Control,
  type FieldArrayPath,
  type FieldValues,
  useFieldArray,
} from 'react-hook-form'
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'

import { colors } from '../../theme/main'
import { LabelWrapper } from './shared/label-wrapper'

type FormFieldArrayProps<
  TFieldValues extends FieldValues,
  TName extends FieldArrayPath<TFieldValues>,
  TFieldArrayItem = TFieldValues[TName] extends Array<infer U> ? U : never,
> = {
  control: Control<TFieldValues>
  name: TName
  renderField: (index: number) => ReactNode
  isRequired?: boolean
  minItems?: number
  maxItems?: number
  disabled?: boolean
  defaultValue: TFieldArrayItem
  helperTextKey?: I18nKey
  addNewButtonKey?: I18nKey
  hideLabel?: boolean
  arrayItemSx?: SystemStyleObject
  addNewButtonProps?: ButtonProps
} & ReactNodeOrI18nKey<'label'>

export const FormFieldArray = <
  TFieldValues extends FieldValues,
  TName extends FieldArrayPath<TFieldValues>,
  TFieldArrayItem = TFieldValues[TName] extends Array<infer U> ? U : never,
>({
  control,
  name,
  renderField,
  isRequired,
  minItems = 0,
  maxItems = Infinity,
  disabled,
  defaultValue,
  label,
  labelKey,
  helperTextKey,
  addNewButtonKey = 'common.addNew',
  hideLabel,
  arrayItemSx,
  addNewButtonProps,
}: FormFieldArrayProps<TFieldValues, TName, TFieldArrayItem>) => {
  const { fields, remove, append } = useFieldArray({
    name,
    control,
    rules:
      isRequired || minItems > 0 ? { required: true, minLength: minItems } : undefined,
  })

  return (
    <Stack>
      <FormControl isRequired={!!isRequired}>
        <LabelWrapper
          name={name}
          label={label}
          labelKey={labelKey}
          hideLabel={hideLabel}
        />
        {!!helperTextKey && (
          <FormHelperText fontSize={12} color={colors.gray[500]}>
            <FormattedMessage id={helperTextKey} />
          </FormHelperText>
        )}
      </FormControl>

      {fields.map((field, index) => (
        <HStack key={field.id} align="flex-start" sx={arrayItemSx}>
          {renderField(index)}
          <IconButton
            isDisabled={
              disabled ||
              (isRequired && fields.length <= (minItems || 1)) ||
              fields.length <= minItems
            }
            variant="ghost"
            colorScheme="blackAlpha"
            fontSize={24}
            aria-label=""
            onClick={() => remove(index)}
            icon={<FiMinusCircle />}
          />
        </HStack>
      ))}
      {fields.length < maxItems && (
        <Button
          alignSelf="flex-start"
          variant="ghost"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          onClick={() => append(defaultValue)}
          isDisabled={disabled}
          leftIcon={<FiPlusCircle fontSize={24} />}
          {...addNewButtonProps}
        >
          <FormattedMessage id={addNewButtonKey} />
        </Button>
      )}
    </Stack>
  )
}
